<label [attr.for]="formControlName" class="form-label {{isRequired()?'required':''}}">{{ label }}</label>
<select
    class="form-select"
    [id]="formControlName"
    [formControl]="control"
    (change)="onChange($event.target.value)"
    [tabindex]="isReadOnly?'-1':tabindex"
    >
    <option value=""></option>
    <option *ngFor="let item of itens" value="{{item.value}}">
        {{item.label}}
    </option>
    <!-- [readonly]="isReadOnly" -->
</select>
<form-field-error [form-control]="control"></form-field-error> 