import { SubGrupoCiclo } from './../veiculo/shared/model/sub-grupo-ciclo.model';
import { ComunicacaoService } from './../../shared/services/comunicacao.service';
import { StringMapWithRename } from '@angular/compiler/src/compiler_facade_interface';
import { Component, OnInit } from '@angular/core';
import { OrcamentoService } from '../../shared/services/orcamento.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, RequiredValidator, Validators } from '@angular/forms';
import { RestService } from '../../shared/services/rest.service';
import { SessionService } from '../../shared/services/session.service';
import { UpperCasePipe } from '@angular/common';
import { MessageService } from '../../shared/message.service';

@Component({
  selector: 'app-atribuicao',
  templateUrl: './atribuicao.component.html',
  styleUrls: ['./atribuicao.component.css']
})
export class AtribuicaoComponent implements OnInit {
  uuidEmpOrcamento: string;
  uuidEmpResp: string;
  resourceForm: FormGroup;
  uuid: string;
  responsaveis: Array<any> = [];
  nomeEmpResp: string;
  responsavel: any;
  tipoEmpResp: string;
  isReadOnly: boolean = false;
  protocolo: string;
  atribuicaonome: string;
  uuid_oficina: string;
  empresas: Array<any>;
  habilitaTipo: boolean = true;
  empresaSelecionada: string = 'Empresa';
  tipo_empresa: any;
  usu_resp_atri: any = null;
  empresaAtribuida: any = null;
  empresaAtribuidaTipo: any = null;
  versaoVisual: string = "";
  valores: Array<any> = [];
  //pecas: Array<any> = [];
  constructor(
    private formBuilder: FormBuilder,
    private rest: OrcamentoService,
    public bsModalRef: BsModalRef,
    public serv: RestService,
    private sessionService: SessionService,
    private info: MessageService,
    private comu: ComunicacaoService
  ) { }

  ngOnInit(): void {
    this.resourceForm = this.formBuilder.group({
      usuario: [null, Validators.required],
      usuarioEmpresa: [null, Validators.required],
      empresa_resp: [null, Validators.required],
      resp_orcamento: [null, Validators.required],
      protocolo: [null,]
    });

    this.tipo_empresa = this.sessionService.getTipoEmpresa();
    this.nomeEmpResp = this.sessionService.getCurrentRazaoSocial();
    this.tipoEmpResp = this.sessionService.getTipoEmpresa();
    this.uuidEmpResp = this.sessionService.getUUIDEmpresa();
    this.getTipoEmpresaAtual()
    this.getDados()
  }
  getDados() {//2023030600001
    this.rest.getOrcamentoProtocol(this.protocolo).subscribe((data: any) => {
      //console.log(data)
      let dados = data.data[0];
      //console.log(dados)
      this.versaoVisual = dados.versao_visual;
      this.uuid = dados.uuid;
      this.usu_resp_atri = dados.uuid_usu_atri;
      this.empresaAtribuida = (dados.uuid_emp_atribuicao == null) ? this.sessionService.getUUIDEmpresa() : dados.uuid_emp_atribuicao;
      this.empresaAtribuidaTipo = (dados.empresaatritipo == null) ? this.sessionService.getTipoEmpresa() : dados.empresaatritipo;
      //console.log(dados)
      //if (dados.empresaatritipo == this.tipo_empresa) {
      let t;
      switch (this.empresaAtribuidaTipo) {
        case 'CORPORATE': t = 'Associação'; break;
        case 'OFICINA': t = 'Oficina'; break;
        case 'REGULADORA': t = 'Reguladora'; break;
        default: t = null
      }
      this.resourceForm.get('usuarioEmpresa')?.setValue(t);
      if (this.tipoEmpResp == 'OFICINA')
        this.resourceForm.get('empresa_resp')?.setValue(this.uuidEmpResp)
      //console.log(this.empresaAtribuida)
      this.onSelectTipoEmp(true);
      this.empresaSelecionada = t;
      //this.resourceForm.get('resp_orcamento')?.setValue(dados.uuid_usu_atri);
      //}
    })
    //this.getTipoEmpresaAtual()
  }
  onSelectEmp() {
    let tipo = this.resourceForm.get('usuarioEmpresa')?.value;
    let postData = {
      "method": "GET",
      "uuidOrcamento": this.uuid,
      "tipo_emp": tipo,
      "uuid": ((tipo == 'Associação') ? (this.uuidEmpOrcamento) : ((tipo == 0) ? this.uuidEmpResp : null))
    }
    this.rest.getUsersByTipoEmpresa(postData).subscribe((data: any) => {
      if (data.mensagem != 'Empresa sem usuários registrados!') {
        this.responsaveis = []
        data.data.forEach((data) => {
          this.responsaveis.push({ "value": data.uuid_usuario, "label": ((data.fantasia == null || data.fantasia == '') ? this.nomeEmpResp : data.fantasia) + ' - ' + data.nome })
        })
        this.resourceForm.get('usuario')?.setValue('')
      }
      else this.responsaveis = []
    });
  }
  getTipoEmpresaAtual() {//Obter qual o tipo da empresa que o orçamento esta atribuido
    let tipo = this.tipoEmpResp
    //console.log(tipo)
    if (tipo != null && tipo != '') {
      if (tipo == 'CORPORATE') {
        this.responsavel = [
          { value: 'Associação', label: 'Perito' },
          { value: 'Oficina', label: 'Oficina' },
          { value: 'Reguladora', label: 'Reguladora' }
        ];
      }
      else if (tipo == 'OFICINA') {
        this.responsavel = [
          { value: 'Oficina', label: 'Oficina' }
        ];
        this.empresas = [
          { value: this.uuidEmpResp, label: this.nomeEmpResp }
        ]
        this.resourceForm.get('usuarioEmpresa')?.setValue('Oficina')
        this.resourceForm.get('empresa_resp')?.setValue(this.uuidEmpResp)
        this.habilitaTipo = false
        this.onSelectTipoEmp();
        this.onSelectEmpresa()
      }
      else if (tipo == 'REGULADORA') {
        this.responsavel = [
          { value: 'Reguladora', label: 'Reguladora' },
          { value: 'Oficina', label: 'Oficina' }
        ];
        /* this.empresas = [
          { value: this.uuidEmpResp, label: this.nomeEmpResp }
        ] */
        //this.resourceForm.get('usuarioEmpresa')?.setValue(0)
        this.habilitaTipo = true
        //this.onSelectTipoEmp();
        //this.resourceForm.get('empresa_resp')?.setValue(this.uuidEmpResp)
        //this.onSelectEmpresa()
      }
      else { // caso não tenha usuário definido
        this.responsavel = [
          { value: 'Associação', label: 'Perito' },
          { value: 'Oficina', label: 'Oficina' },
          { value: 'Reguladora', label: 'Reguladora' }
        ];
      }
    }
  }
  selectTodos(uuid) {
    if (uuid == this.sessionService.getUUIDEmpresa())
      return true
    return false
  }
  onSelectEmpresa(uuid = null, initial = false) {
    if (uuid == null)
      uuid = this.resourceForm.get('empresa_resp')?.value;
    if (uuid != null) {
      this.rest.getUsuarioResponsavelUUIDEmp(uuid, this.selectTodos(uuid)).subscribe((data: any) => {
        //console.log(data)
        this.responsaveis = []
        if (data.data.status != 'erro' && data.data.length > 0) {
          this.resourceForm.get('resp_orcamento')?.setValue(null)
          data.data.forEach((data) => {
            this.responsaveis.push({ "value": data.uuid_usuario, "label": data.nome })
          })
          let empresaSelec = this.resourceForm.get('usuarioEmpresa')?.value
          switch (this.tipoEmpResp) {
            case ('CORPORATE'): {
              if (initial)
                this.resourceForm.get('resp_orcamento')?.setValue(this.usu_resp_atri)
              else if (empresaSelec == 'Associação')
                this.resourceForm.get('resp_orcamento')?.setValue(null)
              else if (empresaSelec == 'Oficina')
                this.resourceForm.get('resp_orcamento')?.setValue(data.data[0].uuid_usuario)
              else if (empresaSelec == 'Reguladora')
                this.resourceForm.get('resp_orcamento')?.setValue(data.data[0].uuid_usuario)
              break;
            }
            case ('REGULADORA'): {
              if (empresaSelec == 'Oficina')
                this.resourceForm.get('resp_orcamento')?.setValue(data.data[0].uuid_usuario)
              break;
            }
            default: null
          }
        }
        else {
          this.resourceForm.get('empresa_resp')?.setValue(null);
          this.info.showErro('Não existem usuários na empresa selecionada!');
        }
      })
    }
    /* if (this.resourceForm.get('usuarioEmpresa')?.value == 'Oficina') {
      this.resourceForm.get('oficina')?.setValue(uuid)
    } */
  }
  retornaType(tipo) {
    switch (this.tipoEmpResp) {
      case ('CORPORATE'): {
        if (tipo != 'Associação')
          return 'empresas_relacionadas';
        break;
      }
      case ('REGULADORA'): {
        if (tipo == 'Oficina')
          return 'empresas_relacionadas';
        break;
      }
      default: return null;
    }
  }
  onSelectTipoEmp(initial = false) {/* aqui */
    let tipo = this.resourceForm.get('usuarioEmpresa')?.value;
    if (this.tipoEmpResp != 'OFICINA') {
      this.resourceForm.get('empresa_resp')?.setValue(null)
    }
    /* if (this.resourceForm.get('resp_orcamento').value == this.usu_resp_atri || this.usu_resp_atri == null)
      this.resourceForm.get('resp_orcamento')?.setValue(null) */
    if (tipo == 0) {
      switch (this.tipoEmpResp) {
        case 'OFICINA': this.empresaSelecionada = 'Oficina'; break;
        case 'REGULADORA': this.empresaSelecionada = 'Reguladora'; break;
        case 'CORPORATE': this.empresaSelecionada = 'Associação'; break;
        default: this.empresaSelecionada = 'Empresa'
      }
    }
    else if (!(tipo == '' || tipo == null))
      this.empresaSelecionada = tipo
    else
      this.empresaSelecionada = 'Empresa'
    //console.log(this.uuidEmpOrcamento);
    let postData = {
      "method": "GET",
      "uuidOrcamento": this.uuidEmpResp,
      "tipo_emp": tipo,
      "uuid": this.sessionService.getUUIDEmpresa(),
      "type": this.retornaType(tipo)
    }
    if (tipo != 'Associação' && this.tipoEmpResp == 'CORPORATE') {// caso a associação esteja vendo seu orçamento que esta atribuido à uma Reguladora ou Oficina
      this.rest.getUsersByTipoEmpresa(postData).subscribe((data: any) => {
        //console.log(data)
        this.resourceForm.get('resp_orcamento')?.setValue(null)
        if (data.data.mensagem != 'Empresa sem usuários registrados!') {
          this.empresas = []
          this.valores = []
          //console.log(data)
          data.data.forEach((data) => {
            //console.log(data)
            this.empresas.push({ "value": data.uuid, "label": data.fantasia, "pintura": data.pintura, "troca": data.troca, "reparacao": data.reparacao, "montagem": data.montagem });
            //this.valores.push({ "uuid": data.uuid, "pintura": data.pintura, "troca": data.troca, "reparacao": data.reparacao, "montagem": data.montagem });
          })
          if ((this.empresaAtribuidaTipo == tipo.toUpperCase()) || data.data.length == 1) {
            this.resourceForm.get('empresa_resp')?.setValue((initial) ? this.empresaAtribuida : ((data.data.length == 1) ? data.data[0].uuid : null));
            if (initial || data.data.length == 1)
              this.onSelectEmpresa(((initial) ? this.empresaAtribuida : data.data[0].uuid), initial)
          }
        }
        else this.empresas = []; this.valores = []
      });
    }
    else if (tipo == 'Associação' && this.tipoEmpResp == 'CORPORATE') {// caso a associação esteja vendo seu orçamento que foi atribuido à um usuário dela mesma
      this.empresas = [
        { "value": this.sessionService.getUUIDEmpresa(), "label": this.sessionService.getCurrentRazaoSocial() }
      ]
      this.resourceForm.get('empresa_resp')?.setValue(this.sessionService.getUUIDEmpresa());
      this.onSelectEmpresa(null, initial);
    }
    else if (tipo == 'Reguladora' && this.tipoEmpResp == 'REGULADORA') {
      this.empresas = [
        { "value": this.sessionService.getUUIDEmpresa(), "label": this.sessionService.getCurrentRazaoSocial() }
      ]
      this.resourceForm.get('empresa_resp')?.setValue(this.sessionService.getUUIDEmpresa());
      this.onSelectEmpresa(null, initial);
    }
    else if (tipo == 'Oficina' && this.tipoEmpResp == 'REGULADORA') {// para uma reguladora que tenha atribuido seu orçamento à uma oficina
      this.rest.getUsersByTipoEmpresa(postData).subscribe((data: any) => {
        if (data.data.mensagem != 'Empresa sem usuários registrados!') {
          this.empresas = [];
          this.valores = []
          //console.log(data)
          data.data.forEach((data) => {
            this.empresas.push({ "value": data.uuid, "label": data.fantasia });
            this.valores.push({ "pintura": data.pintura, "troca": data.troca, "reparacao": data.reparacao, "montagem": data.montagem });
          })
          //this.resourceForm.get('resp_oficina')?.setValue('')
        }
        else this.empresas = []; this.valores = []
      });
    }
    else {
      this.rest.getUsersByTipoEmpresa(postData).subscribe((data: any) => {
        if (data.mensagem != 'Empresa sem usuários registrados!') {
          this.responsaveis = []
          //console.log(data)
          //console.log(postData)
          if (data.data.length > 0) {
            data.data.forEach((data) => {
              this.responsaveis.push({ "value": data.uuid_usuario, "label": ((data.fantasia == null || data.fantasia == '' && this.tipoEmpResp != 'OFICINA') ? (this.nomeEmpResp == null) ? ' - ' + data.fantasia : ' - ' + this.nomeEmpResp : '') + data.nome });
              this.valores.push({ "pintura": data.pintura, "troca": data.troca, "reparacao": data.reparacao, "montagem": data.montagem });
            });
          }
          //this.resourceForm.get('resp_oficina')?.setValue('')
        }
        else this.responsaveis = []
      });
    }
    //this.checkDisplayResponsalvel()
  }
  checkDisplayResponsalvel() {
    if ((this.tipoEmpResp == 'OFICINA') || (this.tipoEmpResp == 'CORPORATE' && this.resourceForm.get('usuarioEmpresa')?.value == 'Associação') || (this.tipoEmpResp == 'REGULADORA' && this.resourceForm.get('usuarioEmpresa')?.value == 'Reguladora')) {
      return 'display: block;'
    }
    return 'display: none;'
  }

  //recalculo
  calcularHoraCentesimal(horaStr: string): number {
    let horaCentesimal = 0
    //console.log(horaStr)
    if (horaStr && horaStr.indexOf(".")) {
      let hora: number = parseInt(horaStr.split('.', 2)[0]);
      let minHot: number = 0
      if (horaStr.split('.', 2)[1] != null)
        minHot = parseInt(horaStr.split('.', 2)[1]) / 60.00;
      horaCentesimal = hora + minHot;
      //console.log('hora: ', hora, ' minutos: ', minHot, ' tempo: ', horaCentesimal)
    } else {
      //já tá convertida
      horaCentesimal = parseFloat(horaStr);
      //console.log('tempo ', horaCentesimal)
    }
    //console.log(Number(horaCentesimal.toFixed(3)))
    return Number(horaCentesimal.toFixed(3));
  }
  onSubmit() {
    /* if (this.resourceForm.invalid) {
      //console.log(this.resourceFormPeca)
      this.resourceForm.markAllAsTouched();
      return;
    } */
    //console.log(this.resourceForm.get('combustivel')?.value);return;
    let resp = (this.resourceForm.get('resp_orcamento')?.value == 0 ? null : this.resourceForm.get('resp_orcamento')?.value)
    let tipo = this.resourceForm.get('usuarioEmpresa').value
    //console.log(tipo)
    //recalculo
    if (tipo == 'Oficina') {
      let empSelec = this.resourceForm.get('empresa_resp').value
      if (empSelec != this.empresaAtribuida) {
        let pintura, troca, remocao_instalacao, reparo
        for (let i = 0; i < this.empresas.length; i++) {
          if (this.empresas[i].value == empSelec) {
            pintura = this.empresas[i].pintura
            troca = this.empresas[i].troca
            remocao_instalacao = this.empresas[i].montagem
            reparo = this.empresas[i].reparacao
            break;
          }
        }
        this.rest.getItensByUUID(this.uuid).subscribe((data: any) => {
          let dados: Array<any> = data.data;
          if (dados.length != 0) {
            let servico = [pintura, troca, remocao_instalacao, reparo];
            console.log(servico)
            let tipoServico = ['pintura', 'troca', 'remocao_instalacao', 'reparo'];
            let valor_hora;
            let tempo;
            let valor_servico = new Array;
            let vAux;
            let itens = new Array;
            let vServicoAux;
            let desconto, subtotal, liquido, bruto, uuidItem;
            //console.log(dados)


            for (let i = 0; i < dados.length; i++) {
              //let pintura, troca, remocao_instalacao, reparo
              //console.log('repeticao ' + i)
              vAux = new Object
              vServicoAux = 0
              for (let j = 0; j < tipoServico.length; j++) {
                let aux = (dados[i][tipoServico[j] + "_tempo"])
                tempo = this.calcularHoraCentesimal((aux == '0' || aux == 'NaN') ? 0 : aux);
                valor_hora = parseFloat(servico[j]);
                //console.log("valor hora", valor_hora, ' tempo: ', tempo)
                vAux[tipoServico[j] + '_valor_servico'] = parseFloat((valor_hora * tempo).toFixed(2));
                vAux[tipoServico[j] + '_valor_hora'] = valor_hora
                vAux[tipoServico[j] + '_tempo'] = tempo
                //console.log(tipoServico[j], ' ', Number(valor_hora * tempo).toFixed(2))
                vServicoAux = parseFloat(vServicoAux) + parseFloat((valor_hora * tempo).toFixed(2));
                //console.log('total',vServicoAux)
              }
              uuidItem = dados[i].uuid
              desconto = parseFloat(dados[i].valor_desconto)
              subtotal = parseFloat(dados[i].valor_subtotal)
              bruto = parseFloat((subtotal + vServicoAux).toFixed(2))
              liquido = parseFloat((bruto * desconto / 100).toFixed(2))

              //console.log(vAux)
              //vAux.total_servico == vServicoAux;
              valor_servico[i] = vAux
              valor_servico[i].total_servico = vServicoAux;
              valor_servico[i].desconto = desconto;
              valor_servico[i].subtotal = subtotal;
              valor_servico[i].bruto = bruto;
              valor_servico[i].liquido = liquido;
              valor_servico[i].uuid = uuidItem;
              //console.log(vAux)
            }
            //console.log(dados.length)
            for (let i = 0; i < dados.length; i++) {
              itens.push({ "uuid": dados[i].uuid, "valores": valor_servico[i] })
            }
            //console.log(itens)
            //this.pecas = itens
            //console.log(this.pecas)
            let postData = {
              "uuidOrcamento": this.uuid,
              "itens": itens,
              'flag': 'mult',
              "type": 'valores'
            }
            //console.log(postData)
            this.rest.salvaValores(postData).subscribe((data: any) => {
              //console.log(data)
            })
          }
        });
      }
    }
    //console.log(resp)
    if (resp != null) {
      let postData = {
        "uuidOrcamento": this.uuid,
        "status": "EXECUÇÃO",
        "flag": 'ATRIBUICAO',
        "responsavel": (this.resourceForm.get('resp_orcamento')?.value == 0 ? null : this.resourceForm.get('resp_orcamento')?.value),
      }

      //send Rest
      this.serv.salvarAtribuicao(postData).subscribe(
        data => {
          //let versao:number = Number(this.versaoVisual.replace(/[^0-9]/g,''));
          let versao = "V1.0";
          this.rest.criarVersao(this.protocolo, versao).subscribe((dataV: any) => {
            //console.log(dataV)
            if (dataV.status == 'success' || dataV.code == '404') {
              //console.log(data)
              var response = JSON.parse(JSON.stringify(data));
              if (response.status == 'success') {
                this.bsModalRef.hide();
                this.comu.filter('closed-atribuicao');
              }
            }
          });
        }
      );
    }
    else {
      this.info.showErro('Usuário não encontrado!');
    }
  }
}
/* this.bsModalRef.hide();
      this.rest.filter('closed'); */