//import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GenericInputFieldComponent } from '../generic-input-field/generic-input-field.component';

@Component({
  selector: 'app-input-field-currency',
  templateUrl: './input-field-currency.component.html',
  styleUrls: ['./input-field-currency.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputFieldCurrencyComponent),
    multi: true
  }]
})
export class InputFieldCurrencyComponent extends GenericInputFieldComponent {

  @Input() isCurrency: boolean = false;
  @Input() autoComplete: boolean;

  constructor(injector: Injector,
    private cdRef: ChangeDetectorRef) {
    super(injector);
  }

  //resolve problema erro console setValue not defined 
  public ngDoCheck() {
    //resolve: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after ...
    this.cdRef.detectChanges();
  }
}
