<label [attr.for]="formControlName" class="form-label {{isRequired()?'required':''}}">{{ label }}</label>

<ng-select
    [id]="formControlName"
    [formControl]="control"
    (change)="onChange($event)"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    notFoundText="Não localizado" 
    placeholder="Selecione ..."
    [readonly]="isReadOnly"
    [tabindex]="isReadOnly?'-1':tabindex"
    >
    <ng-option *ngFor="let iten of itens" [value]="iten.value">{{iten.label}} <span class="tag-select" [style.display]="(iten.value_other != null && iten.value_other != '')?'':'none'"> {{(iten.value_other != null)?iten.value_other:''}}</span></ng-option>
</ng-select>

<form-field-error [form-control]="control"></form-field-error>