import { NgModule, Component } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BreadCrumbComponent } from './components/bread-crumb/bread-crumb.component';
import { RouterModule } from '@angular/router';

import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormFieldErrorComponent } from './components/form-field-error/form-field-error.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ModalFormComponent } from './components/modal-form/modal-form.component';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { InputFieldCheckboxComponent } from './components/input-field-checkbox/input-field-checkbox.component'
import { InputFieldCurrencyComponent } from './components/input-field-currency/input-field-currency.component'
import { InputFieldDateComponent } from './components/input-field-date/input-field-date.component'
import { InputFieldDateMonthYearComponent } from './components/input-field-date-month-year/input-field-date-month-year.component';
import { InputFieldHourComponent } from './components/input-field-hour/input-field-hour.component'
import { InputFieldPercentComponent } from './components/input-field-percent/input-field-percent.component'
import { InputFieldTextAreaComponent } from './components/input-field-text-area/input-field-text-area.component'
import { GenericSmartSearchFieldComponent } from './components/generic-smart-search-field/generic-smart-search-field.component'
import { AuthenticationCicloService } from '../core/authentication/authentication-ciclo.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { LoaderComponent } from './loader/loader.component';
import { SidenavbarComponent } from './sidenavbar/sidenavbar.component';
import { SelectFieldComponent } from './components/select-field/select-field.component';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectFieldSearchComponent } from './components/select-field-search/select-field-search.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { InputFieldRangeComponent } from './components/input-field-range/input-field-range.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ButtonStatusNumComponent } from './components/button-status-num/button-status-num.component';
import { InputFieldHideComponent } from './components/input-field-hide/input-field-hide.component';
import { FormsModule } from '@angular/forms';
import { InputFieldImageComponent } from './components/input-field-image/input-field-image.component';
import { InputFieldInfoComponent } from './components/input-field-info/input-field-info.component';
import { SelectFieldSearchBtnComponent } from './components/select-field-search-btn/select-field-search-btn.component';
import { SelectFieldSearchTagComponent } from './components/select-field-search-tag/select-field-search-tag.component';

@NgModule({
  declarations: [
    FormFieldErrorComponent,
    PageHeaderComponent,
    BreadCrumbComponent,
    GenericSmartSearchFieldComponent,
    ModalFormComponent,
    InputFieldComponent,
    InputFieldCheckboxComponent,
    InputFieldCurrencyComponent,
    InputFieldDateComponent,
    InputFieldDateMonthYearComponent,
    InputFieldHourComponent,
    InputFieldPercentComponent,
    InputFieldTextAreaComponent,
    InputFieldHideComponent,
    SpinnerComponent,
    LoaderComponent,
    SidenavbarComponent,
    SelectFieldComponent,
    SelectFieldSearchComponent,
    ProgressBarComponent,
    InputFieldRangeComponent,
    ButtonStatusNumComponent,
    InputFieldImageComponent,
    InputFieldInfoComponent,
    SelectFieldSearchBtnComponent,
    SelectFieldSearchTagComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    NgSelectModule,
    NgxPaginationModule,
    FormsModule
  ],
  providers: [
    AuthenticationCicloService,
    CurrencyPipe
  ],
  exports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormFieldErrorComponent,
    PageHeaderComponent,
    BreadCrumbComponent,
    PageHeaderComponent,
    ModalFormComponent,
    GenericSmartSearchFieldComponent,
    InputFieldComponent,
    SelectFieldComponent,
    InputFieldCheckboxComponent,
    InputFieldCurrencyComponent,
    InputFieldDateComponent,
    InputFieldDateMonthYearComponent,
    InputFieldHourComponent,
    InputFieldPercentComponent,
    InputFieldTextAreaComponent,
    InputFieldHideComponent,
    TabsModule,
    ModalModule,
    SpinnerComponent,
    LoaderComponent,
    SidenavbarComponent,
    SelectFieldSearchComponent,
    NgxPaginationModule,
    ProgressBarComponent,
    InputFieldRangeComponent,
    ButtonStatusNumComponent,
    InputFieldImageComponent,
    InputFieldInfoComponent,
    SelectFieldSearchBtnComponent,
    SelectFieldSearchTagComponent
  ]
})
export class SharedModule { }
