// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  empresa_master: '2538C00D-5CF8-497C-8856-E890A3CB6555C9341FDE-88D3-4883-9EDE-128C67BCEBAD7DC956D2-9383-48B7-ADD6-02BDC4702AFD',

  //temporário
  apiSigaUrl: 'http://177.70.23.181/webrunstudio/',
  apiSigaKey: '2AF5B586-3955-4D7E-937F-D72545F21466',
  apiCicloUrl: '',
  userApiCiclo: '',
  passwordApiCiclo: '',

  //rest API
  apiSigaParts: "https://api.sigaparts.com.br/webrunstudio/",
  secretKeyGlobal: "14E3016F-65A9-4B3B-A57A-0FAC7CABEF1CDEADD186-7392-4740-848B-38595213EC4C80D0E260-A48A-4A44-963F-21A44E05CD1E",
  marital_status: [{ value: 'Solteiro', label: 'Solteiro' },
  { value: 'Casado', label: 'Casado' },
  { value: 'Separado', label: 'Separado' },
  { value: 'Divorciado', label: 'Divorciado' },
  { value: 'Viúvo', label: 'Viúvo' }
  ],

  genders: [{ value: 'M', label: 'Feminino' }, { value: 'M', label: 'Masculino' }],

  //tipos serviços realizados
  tipo_servico: [{ value: 'troca', label: 'Troca', valor_hora: 10.50 },
  { value: 'reparo', label: 'Reparação', valor_hora: 65.50 },
  { value: 'pintura', label: 'Pintura', valor_hora: 32.54 },
  { value: 'remocao_instalacao', label: 'Remoção e Instalação', valor_hora: 40 }
  ],

  //lista de grupos dos logins
  groups: [
    //{value:"1", label:'Diretor'},	
    { value: "2", label: 'Adminitrador' },//arco e empresa	
    { value: "3", label: 'Configuração' },//arco
    { value: "4", label: 'Suporte' },//arco	
    { value: "5", label: 'Orçamentista' },//old: Comercial
    { value: "6", label: 'Analista' },//empresa	
    { value: "7", label: 'Regulador' },	//empresa
    //{value:"8", label:'Gerente'} //empresa
  ],
  coresVeiculo: [
    { value: "AMARELO", label: "AMARELO" },
    { value: "AZUL", label: "AZUL" },
    { value: "BEGE", label: "BEGE" },
    { value: "BRANCA", label: "BRANCA" },
    { value: "CINZA", label: "CINZA" },
    { value: "DOURADA", label: "DOURADA" },
    { value: "LARANJA", label: "LARANJA" },
    { value: "MARROM", label: "MARROM" },
    { value: "PRATA", label: "PRATA" },
    { value: "PRETO", label: "PRETO" },
    { value: "ROSA", label: "ROSA" },
    { value: "ROXA", label: "ROXA" },
    { value: "VERDE", label: "VERDE" },
    { value: "VERMELHA", label: "VERMELHA" },
    { value: "VINHO", label: "VINHO" }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
