<div [class]="switch?'form-check form-switch':'custom-control custom-checkbox'">
  <input
    type="checkbox"
    class="custom-control-input form-check-input"
    [id]="formControlName"
    [formControl]="control"
    [readonly]="isReadOnly">
    <label [attr.for]="formControlName" class="custom-control-label form-check-label">{{ label }}</label>
  <form-field-error [form-control]="control"></form-field-error>
</div>

