import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-field-image',
  templateUrl: './input-field-image.component.html',
  styleUrls: ['./input-field-image.component.css']
})
export class InputFieldImageComponent implements OnInit {

  @Input() styled:any

  constructor() { }

  ngOnInit(): void {
    this.inicializar() 
  }

  inicializar() {
    const inputFile = document.querySelector("#picture__input");
    const pictureImage = document.querySelector(".picture__image");
    const pictureImageTxt = "Insira a imagem";
    pictureImage.innerHTML = pictureImageTxt;
    
    inputFile.addEventListener("change", function (e) {
      const inputTarget:any = e.target;
      const file:any = inputTarget.files[0];
    
      if (file) {
        const reader = new FileReader();
        reader.addEventListener("load", function (e) {
          const readerTarget = e.target;
          const img:any = document.createElement("img");
          img.src = readerTarget.result;
          img.classList.add("picture__img");
          img.classList.add("img-fluid");
          

          pictureImage.innerHTML = "";
          pictureImage.appendChild(img);
        });
    
        reader.readAsDataURL(file);
      } else {
        pictureImage.innerHTML = pictureImageTxt;
      }
    });
    
  }
}
