<label [attr.for]="formControlName" class="form-label {{isRequired()?'required':''}}">{{ label }}</label>
<input
type="time"
min="00:00"
max="23:59"
class="form-control"
[id]="formControlName"
[formControl]="control"
[value]="value"
[readonly]="isReadOnly"
[tabindex]="isReadOnly?'-1':tabindex"
>
<form-field-error [form-control]="control"></form-field-error>