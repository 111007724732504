import { MessageService } from './../shared/message.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../shared/services/session.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  private isAuthenticated: boolean = false;

  constructor(private session: SessionService,
              private route: ActivatedRoute,
              private router: Router,
              private msg: MessageService) { }

  canActivate(
              route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot
              ) : boolean {
    //get path app-routing.module
    let path = route.routeConfig.path;
    let url = state.url;
    //console.log(url);

    this.isAuthenticated = this.session.isAuthenticated();
    if(this.isAuthenticated) {
      //alow groups
      //let roles = route.data["roles"];
      return this.isAllowUser(route.data); 
    }
    this.router.navigate(['/login/login-form']);
  }

  showErrorAutorize(codigo:number){
    this.msg.showErro('Acesso não permitido');
    console.error('Acesso não permitido: '+ codigo);
  }

  isAllowUser(data):boolean{
      let roles = data["roles"];//array grupos permitidos
      let rolesTipo = data["rolesTipo"];//array tipoEmpresa permitido
      let onlyMaster = data["onlyMaster"];//boolean se rota permitida somente para empresa Master

      if(onlyMaster!=undefined && this.session.isMaster()==false){
        this.showErrorAutorize(1)
        return false;
      }

      //restrito a grupos/niveis?
      if(roles != undefined && roles.length>0){   
        //check in group
        let grupoId = this.session.getUserSession()?.grupo_id;

        //is master empresa e grupo de configuração/suporte?
        if(this.session.isMaster() && (grupoId==grupo.SUPERADMIN || grupoId==grupo.SUPORTE)){
          return true;
        } 

        //Permissão baseada somente no grupo?
        if(Array.isArray(roles)){          
          if(this.checkInGrupo(roles) && this.checkInTipoEmpresa(rolesTipo)){
            return true;
          }   
          console.log(roles)     
          this.showErrorAutorize(2)
          return false;
        }else if (typeof roles == "object") {
          //roles['CORPORATE']
          //roles['REGULADORA']
          //roles['OFICINA']
          let tipoEmpresa = this.session.getTipoEmpresa();
          if(tipoEmpresa == undefined || roles[tipoEmpresa] == undefined || Array.isArray(roles[tipoEmpresa])==false){
            this.showErrorAutorize(3)
            return false;
          }else{
            if(this.checkInGrupo(roles[tipoEmpresa])){
              return true;
            }        
            this.showErrorAutorize(4)
            return false;
          }
        }else{
          this.showErrorAutorize(5)
          return false;
        }

      }else{
        //all users logado permitido
        return true;
      }     
  }

  checkInTipoEmpresa(tipoArray:Array<any>){
    let tipoEmpresa = this.session.getTipoEmpresa();
    if(tipoEmpresa==undefined){
      return false;
    }
    //se não especificar o tipo de empresa permitido, significa que todas podem acessar
    if(tipoArray == undefined || tipoArray.length==0 || tipoArray.includes(tipoEmpresa)){
      return true;
    }
  }

  checkInGrupo(grupoArray:Array<any>){
    let grupoId = this.session.getUserSession()?.grupo_id;
    if(grupoId==undefined || grupoArray == undefined){
      return false;
    }
    if(grupoArray.includes(grupoId)){
      return true;
    }
  }

}


//perfil/grupo
export const grupo = {
  SUPERADMIN: '3',
  ADMINISTRADOR: '2',
  SUPORTE: '4',
  ORCAMENTISTA: '5',
  ANALISTA: '6',
  REGULADOR: '7'  
};

export const tipo = {  
   CORPORATE:'CORPORATE',
   REGULADORA:'REGULADORA',
   OFICINA:'OFICINA'
};
