import { Component, Input, forwardRef, Injector, ChangeDetectorRef   } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GenericInputFieldComponent } from '../generic-input-field/generic-input-field.component';

@Component({
  selector: 'app-input-field-hide',
  templateUrl: './input-field-hide.component.html',
  styleUrls: ['./input-field-hide.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputFieldHideComponent),
    multi: true
  }]
})
export class InputFieldHideComponent extends GenericInputFieldComponent {

  constructor(injector: Injector,
              private cdRef: ChangeDetectorRef
    ) {
    super(injector);
   }

   public ngDoCheck() {
    //resolve: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after ...
		this.cdRef.detectChanges();
	}

}
