import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GenericInputFieldComponent } from '../generic-input-field/generic-input-field.component';

@Component({
  selector: 'app-input-field-text-area',
  templateUrl: './input-field-text-area.component.html',
  styleUrls: ['./input-field-text-area.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputFieldTextAreaComponent),
    multi: true
  }]
})
export class InputFieldTextAreaComponent extends GenericInputFieldComponent {

  @Input() numberLines: number = 5;

  constructor(injector: Injector) {
    super(injector);
  }

}
