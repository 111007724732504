<div class="modal-dialog modal-md mt-0 mb-0" role="document">
    <div class="modal-content">
        <div class="modal-body">
            <button type="button" aria-label="Close" (click)="bsModalRef.hide()" class="btn-close btn-danger float-end"
                data-bs-dismiss="modal" aria-label="Close">
            </button>

            <div class="col-lg-12 col-md-12 col-sm-12 mb-4">
                <h4 class="titulo_seccao">Atribuição</h4>
            </div>

            <form [formGroup]="resourceForm" class="formDev">
                <app-input-field-hide formControlName="protocolo"></app-input-field-hide>
                <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <app-select-field-search label="Tipo" formControlName="usuarioEmpresa" [itens]="responsavel"
                            (change)="onSelectTipoEmp(false)" [isReadOnly]="!(this.habilitaTipo)">
                        </app-select-field-search>
                    </div>
                    <div class="col-6">
                        <app-select-field-search [label]="empresaSelecionada" (change)="onSelectEmpresa()" formControlName="empresa_resp" [itens]="empresas" [isReadOnly]="!(this.habilitaTipo) || this.resourceForm.get('usuarioEmpresa')?.value == 'Associação' || (this.resourceForm.get('usuarioEmpresa')?.value == 'Reguladora') && (this.tipoEmpResp == 'REGULADORA')"><!--  -->
                        </app-select-field-search>
                    </div>
                    <div class="col-6">
                        <app-select-field-search [itens]="responsaveis" label="Responsável"
                            formControlName="resp_orcamento" [isReadOnly]="isReadOnly"  [style]="checkDisplayResponsalvel()" ><!--  -->
                        </app-select-field-search>
                    </div>
                </div>
            </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="onSubmit()">Confirmar</button>
            <button id="closeModal" type="button" class="btn btn-danger" data-bs-dismiss="modal"
                (click)="bsModalRef.hide()">Cancelar</button>
        </div>
    </div>
</div>