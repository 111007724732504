<div [formGroup]="formGroup">
  <div *ngIf="showLabel">
    <label for="descricao" class="form-label" >{{ label }}</label>
  </div>

  <div class="input-group flex-nowrap">
    <div class="input-group-prepend">
        <span class="input-group-text" id="addon-wrapping">
          <div *ngIf="showSpinner">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </div>
          <div *ngIf="!showSpinner">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
            </svg>
          </div>
        </span>
    </div>

    <input
      [(ngModel)]="currentValue"
      (ngModelChange)="onChange($event)"
      formControlName="descricao"
      [typeahead]="itens"
      [typeaheadMinLength]="2"
      [typeaheadWaitMs]="100"
      [typeaheadOptionsLimit]="10"
      class="form-control"
      typeaheadOptionField="descricao"
      (typeaheadOnSelect)="onSelectEntity($event)"
      (typeaheadNoResults)="searchEntityNotResults($event)"
      [title]="getPresentation()"
      [readonly]="isReadOnly"
      autocomplete="nope"
     >
  </div>
  <div class="alert alert-danger" *ngIf="noResultEntity">Nenhum registro encontrado</div>

</div>
