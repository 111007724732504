import { Directive, Injector, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControlDirective, FormControl, ControlContainer, AbstractControl, Validators } from '@angular/forms';

/*
  Link de refêrencia
  https://medium.com/angular-in-depth/dont-reinvent-the-wheel-when-implementing-controlvalueaccessor-a0ed4ad0fafd
*/

@Directive()
export class GenericInputFieldComponent implements ControlValueAccessor {

  @ViewChild( FormControlDirective, {static: true}) formControlDirective: FormControlDirective;
  @Input() label: string;
  @Input() formControl: FormControl;
  @Input() formControlName: string;
  @Input() value: string;
  @Input() tooltipsText: string
  @Input() isReadOnly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() tabindex: number;
  
  //public isRequired:boolean = false;

  get control(): AbstractControl {    
    return this.formControl || this.controlContainer?.control?.get(this.formControlName);
  }

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  constructor(private injector: Injector) {
    /*
    setTimeout(() => {
      //label color required
      this.isRequired = false;//default
      let errors = this.controlContainer?.control?.get(this.formControlName)?.errors;
      if(this.formControlName=='fornecedor') console.log(errors)
      if(errors !== null){
        this.isRequired = errors?.required !== undefined;
      } 
    }, 100);
    */


  }

  isRequired():boolean{   
    return this.controlContainer?.control?.get(this.formControlName).hasValidator(Validators.required);
    /*let errors = this.controlContainer?.control?.get(this.formControlName)?.errors;  
    if(errors !== null){
      return errors?.required !== undefined;
    } */
    return false;
  }

  hasLabel(): boolean {
    return this.label?.length > 0
  }

  clearInput(): void {
    this.control.setValue('');
  }

  writeValue(obj: any): void {
    this.formControlDirective.valueAccessor.writeValue(obj);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }

  showLabel(): boolean {
    return !(!this.label);
  }

  
}
