import { Component, forwardRef, Injector, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { GenericInputFieldComponent } from '../generic-input-field/generic-input-field.component';

@Component({
  selector: 'app-input-field-hour',
  templateUrl: './input-field-hour.component.html',
  styleUrls: ['./input-field-hour.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFieldHourComponent),
      multi: true,
    },
  ],
})
export class InputFieldHourComponent  extends GenericInputFieldComponent {

  bsConfig: Partial<BsDatepickerConfig>;

  constructor( injector: Injector, private localseService: BsLocaleService) {
    super(injector);
  }

}
