import { Component, Input, forwardRef, Injector, ChangeDetectorRef   } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GenericInputFieldComponent } from '../generic-input-field/generic-input-field.component';

@Component({
  selector: 'app-input-field-range',
  templateUrl: './input-field-range.component.html',
  styleUrls: ['./input-field-range.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputFieldRangeComponent),
    multi: true
  }]
})
export class InputFieldRangeComponent extends GenericInputFieldComponent {

  @Input() min: number = 0
  @Input() max:number = 100
  @Input() step:number = 1

  constructor(injector: Injector,
              private cdRef: ChangeDetectorRef
    ) {
    super(injector);
   }

   public ngDoCheck() {
		this.cdRef.detectChanges();
	}

}
