import { Component, Input, forwardRef, Injector, Output, EventEmitter, ChangeDetectorRef  } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GenericInputFieldComponent } from '../generic-input-field/generic-input-field.component';

@Component({
  selector: 'app-input-field-checkbox',
  templateUrl: './input-field-checkbox.component.html',
  styleUrls: ['./input-field-checkbox.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputFieldCheckboxComponent),
    multi: true
  }]
})
export class InputFieldCheckboxComponent extends GenericInputFieldComponent {

  @Input() switch: boolean=false;
  constructor(injector: Injector) {
    super(injector);
  }


}
