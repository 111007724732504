import { Component, Input, forwardRef, Injector, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EntrySelect } from '../../models/EntrySelect.model';
import { GenericInputFieldComponent } from '../generic-input-field/generic-input-field.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-select-field-search-btn',
  templateUrl: './select-field-search-btn.component.html',
  styleUrls: ['./select-field-search-btn.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectFieldSearchBtnComponent),
    multi: true
  }]
})
export class SelectFieldSearchBtnComponent extends GenericInputFieldComponent {
  @Input() itens: Array<EntrySelect>
  @Input() bindValue:string ="value";
  @Input() bindLabel:string ="label";
  @Input() btn_label: string
  @Input() class_i: string
  @Input() clickBtn: Function = this.f
  @Output() change = new EventEmitter();
  bsModalRef: BsModalRef;
  constructor(injector: Injector, private cdRef: ChangeDetectorRef,
    private modalService: BsModalService
    ) {
    super(injector);
  }

  onChange(event) {
    //console.log(event);
    this.change.emit(event);
  }

  teste() {
    //console.log('teste');
    //console.log(this.clickBtn)
    this.clickBtn();
  }

  f (){}

  public ngDoCheck() {
    //resolve: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after ...
    this.cdRef.detectChanges();
  }
}
