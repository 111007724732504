import { registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module'
import localePt from '@angular/common/locales/pt';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import {LoaderService} from './shared/loader.service'
import { LoaderComponent } from './shared/loader/loader.component';
import { LoaderInterceptor } from './shared/loader/loader.interceptor';
import {SharedModule} from './shared/shared.module'
import {AuthGuardService} from './guards/auth-guard.service';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AtribuicaoComponent } from './pages/atribuicao/atribuicao.component';
import { VersoesComponent } from './pages/versoes/versoes.component';
import { RelatorioComponent } from './pages/versoes/relatorio/relatorio.component';
import { ComparacaoComponent } from './pages/versoes/comparacao/comparacao.component';


registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    AtribuicaoComponent,
    VersoesComponent,
    RelatorioComponent,
    ComparacaoComponent,
  ],
  imports: [
    CoreModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    CurrencyMaskModule,
    BsDatepickerModule.forRoot(),
  ],
  providers: [
    LoaderService,
    AuthGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
