import { EntrySelect } from './../../models/EntrySelect.model';
import { Component, Input, forwardRef, Injector, Output, EventEmitter, ChangeDetectorRef  } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GenericInputFieldComponent } from '../generic-input-field/generic-input-field.component';

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectFieldComponent),
    multi: true
  }]
})
export class SelectFieldComponent extends GenericInputFieldComponent {
  @Input() itens: Array<EntrySelect>
  @Output() change = new EventEmitter();

  constructor(injector: Injector, private cdRef: ChangeDetectorRef) {
    super(injector);
   }

   onChange(event){
    this.change.emit(event);
   }

   public ngDoCheck() {
    //resolve: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after ...
		this.cdRef.detectChanges();
	}
}
