import { OrcamentoService } from '../../../shared/services/orcamento.service';
import { Component, OnInit } from '@angular/core';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute, Router } from '@angular/router';
import { VeiculoService } from 'src/app/shared/services/veiculo.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VersoesComponent } from 'src/app/pages/versoes/versoes.component';
import { ComunicacaoService } from 'src/app/shared/services/comunicacao.service';

@Component({
  selector: 'app-relatorio',
  templateUrl: './relatorio.component.html',
  styleUrls: ['./relatorio.component.css']
})
export class RelatorioComponent implements OnInit {

  name_car: string
  ano_car: string
  geracao_car: string
  logo_marca: string
  modelo: string
  descricao: string
  versao_car: string

  title = 'html-to-pdf';
  cabecalho: any
  protocolo: string
  placa: string
  chassi: string
  combustivel: string
  quilometragem: string
  cor: string
  evento: string
  associado: string
  cpf_cnpj: string
  email: string
  oficina: string
  contato: string
  responsavel: string
  logo: string

  peca: string
  part_number: string
  origem: string
  fornecimento: string
  tipo: string
  tmo: string
  m_obra: string
  qtd: string
  preco_b: string
  desconto: string
  preco_l: string
  franquia: number = 0
  avaria: number = 0
  total_adicional: number = 0
  valor_deducoes: number = 0
  total_pecas: number = 0

  lista_troca: Array<any> = new Array
  lista_remocao_instalacao: Array<any> = new Array
  lista_reparacao: Array<any> = new Array
  lista_servicos_adicionais: Array<any> = new Array

  lista_funilaria: Array<any> = new Array
  lista_pintura: Array<any> = new Array
  lista_eletrica: Array<any> = new Array
  lista_tapecaria: Array<any> = new Array
  lista_mecanica: Array<any> = new Array
  lista_vidracaria: Array<any> = new Array
  lista_recuperacao: Array<any> = new Array
  lista_servico: Array<any> = new Array
  //mh = mao de obra hora
  mao_hora_funilaria: number = 0
  mao_hora_pintura: number = 0
  mao_hora_eletrica: number = 0
  mao_hora_tapecaria: number = 0
  mao_hora_mecanica: number = 0
  mao_hora_vidracaria: number = 0
  mao_hora_recuperacao: number = 0
  mao_hora_dados_remocao_inst: number = 0
  //mv = mao de obra valor
  mao_valor_funilaria: number = 0
  mao_valor_pintura: number = 0
  mao_valor_eletrica: number = 0
  mao_valor_tapecaria: number = 0
  mao_valor_mecanica: number = 0
  mao_valor_vidracaria: number = 0
  mao_valor_recuperacao: number = 0
  mao_valor_dados_remocao_inst: number = 0
  //mt = mao de obra total
  mao_total_funilaria: number = 0
  mao_total_pintura: number = 0
  mao_total_eletrica: number = 0
  mao_total_tapecaria: number = 0
  mao_total_mecanica: number = 0
  mao_total_vidracaria: number = 0
  mao_total_recuperacao: number = 0
  mao_total_remocao_inst: number = 0

  pi1_lista: Array<any> = new Array
  pi1_total_bruto: number = 0
  pi1_mao_obra: number = 0
  pi1_pecas: number = 0
  pi1_total_liquido: number = 0
  pi1_desconto: number = 0
  pi1_avaria: number = 0
  pi1_adicional: number = 0

  pi2_lista: Array<any> = new Array
  pi2_total_bruto: number = 0
  pi2_mao_obra: number = 0
  pi2_pecas: number = 0
  pi2_total_liquido: number = 0
  pi2_desconto: number = 0
  pi2_avaria: number = 0
  pi2_adicional: number = 0

  pi3_lista: Array<any> = new Array
  pi3_total_bruto: number = 0
  pi3_mao_obra: number = 0
  pi3_pecas: number = 0
  pi3_total_liquido: number = 0
  pi3_desconto: number = 0
  pi3_avaria: number = 0
  pi3_adicional: number = 0

  pi4_lista: Array<any> = new Array
  pi4_total_bruto: number = 0
  pi4_mao_obra: number = 0
  pi4_pecas: number = 0
  pi4_total_liquido: number = 0
  pi4_desconto: number = 0
  pi4_avaria: number = 0
  pi4_adicional: number = 0

  pi5_lista: Array<any> = new Array
  pi5_total_bruto: number = 0
  pi5_mao_obra: number = 0
  pi5_pecas: number = 0
  pi5_total_liquido: number = 0
  pi5_desconto: number = 0
  pi5_avaria: number = 0
  pi5_adicional: number = 0

  pi6_lista: Array<any> = new Array
  pi6_total_bruto: number = 0
  pi6_mao_obra: number = 0
  pi6_pecas: number = 0
  pi6_total_liquido: number = 0
  pi6_desconto: number = 0
  pi6_avaria: number = 0
  pi6_adicional: number = 0

  pi7_lista: Array<any> = new Array
  pi7_total_bruto: number = 0
  pi7_mao_obra: number = 0
  pi7_pecas: number = 0
  pi7_total_liquido: number = 0
  pi7_desconto: number = 0
  pi7_avaria: number = 0
  pi7_adicional: number = 0

  pi8_lista: Array<any> = new Array
  pi8_total_bruto: number = 0
  pi8_mao_obra: number = 0
  pi8_pecas: number = 0
  pi8_total_liquido: number = 0
  pi8_desconto: number = 0
  pi8_avaria: number = 0
  pi8_adicional: number = 0

  tempo: any

  orcamento: any

  mao_totalizador_valor: number = 0
  mao_totalizador_hora: any

  peca_genuina: number = 0
  peca_paralela: number = 0
  peca_desmanche: number = 0
  total_pecas_b: number = 0
  pecas_descontos: number = 0
  total_pecas_l: number = 0
  valor_divergencia: number = 0
  valor_servicos_terceiro: number = 0
  total_orcamento_b: number = 0
  pecas_genuinas_f: number = 0
  pecas_paralelas_f: number = 0
  pecas_desmanche_f: number = 0
  valor_servicos_terceiro_f: number = 0
  total_orcamento_av: number = 0
  deducao_franquia: number = 0
  deducao_avaria: number = 0
  deducao_dnc: number = 0
  total_orcamento_l: number = 0
  ano_veiculo:string;
  lista_deducao_dnc: Array<any> = new Array
  bsModalRef: BsModalRef;
  versao: any = 0;
  observacao: string

  constructor(
    private router: ActivatedRoute,
    private rest: OrcamentoService,
    private modalService: BsModalService,
    private comu: ComunicacaoService
  ) { }

  ngOnInit(): void {

    this.router.params.subscribe(params => {
      this.protocolo = params['protocolo']
      this.versao = params['versao']
    })
    this.gerarRelatorio()
  }

  async gerarRelatorio() {
    this.rest.getVersionamentoByVersao(this.protocolo, this.versao).subscribe((data: any) => {
      console.log(data.data)
      this.getDadosCabecalho(data.data.json_orcamento[0]);
      console.log(data.data.json_itens)
      this.getDadosOrcamento(data.data.json_itens);
      this.gerarTotalAvarias();
    })
  }

  async getDadosCabecalho(data) {
    let dados = data
    //console.log(data)
    //console.log(dados)
    this.observacao = dados.observacao
    this.placa = dados.placa
    this.chassi = dados.chassi
    this.combustivel = this.nivelCombustivel(dados.nivel_combustivel)
    this.quilometragem = dados.quilometragem
    this.cor = dados.cor
    this.evento = this.protocolo
    this.associado = dados.nome
    this.cpf_cnpj = dados.cpf_cnpj
    this.email = dados.email
    this.oficina = dados.id_oficina
    this.contato = `(${dados.ddd}) ${dados.numero}`
    this.responsavel = dados.solicitante
    this.logo_marca = dados.link_logo
    this.logo = data.image
    this.versao_car = dados.versao_descricao
    this.geracao_car = dados.geracao_descricao
    this.modelo = dados.modelo_descricao
    this.franquia = dados.franquia
    this.ano_veiculo = dados.veiculo_descricao
    this.avaria = (dados.avaria == null ? 0 : dados.avaria)
    this.valor_divergencia = (dados.divergencia == null ? 0 : dados.divergencia)
    //console.log(this.logo_marca)
    //console.log(dados)
    this.obterOficina()
    this.gerarTotalDeducoes()
    this.gerarLogo()
  }
  gerarLogo() {
    if (this.logo == '') {
      this.logo = '/assets/sigaParts.png'
    }
  }
  gerarTotalDeducoes() {/* aqui */
    this.valor_deducoes = Number(this.pecas_descontos) + Number(this.franquia) + Number(this.avaria) + Number(this.deducao_dnc)
  }

  obterOficina() {
    this.rest.getOficinaByID(this.oficina).subscribe((data: any) => {
      console.log(this.oficina)
      this.oficina = data.data[0].additionalDetailsCorporate.companyName
    })
  }
  retornaClasseIndicadorTMO(vO, vP) {
    vO = Number(vO)
    vP = Number(vP)
    if (vO == null || vP == null || vO == 0 || vP == 0) {
      return ''
    } else if (vO > vP) {
      return 'fa fa-arrow-up'
    } else if (vO < vP) {
      return 'fa fa-arrow-down'
    } else
      return ''
  }

  retornaClasseIndicadorPreco(vO, vP) {
    vO = Number(vO)
    vP = Number(vP)
    if (vO == null || vP == null || vO == 0 || vP == 0) {
      return ''
    } else if (vO > vP) {
      return 'fa fa-arrow-up'
    } else if (vO < vP) {
      return 'fa fa-arrow-down'
    } else
      return ''
  }

  nivelCombustivel(nivel: string) {
    if (nivel == '0/4')
      return 'N/A'
    return nivel
  }

  retornaSimNao(i) {
    if (i) return 'M'
    return ''
  }

  retorna_display(i) {
    if (i) return ''
    return 'display:none;'
  }

  title_r(i) {
    if (i) return 'Peça adicionada manualmente'
    return ''
  }

  retornabarra(i) {
    if (i) return '|'
    return ''
  }

  async getDadosOrcamento(data) {
    console.log(data);
    this.gerarTabelasServicos(data);
    this.gerarMaoObra(data);
    this.separarPecas(data);
    this.gerarTotalPecas(data);
    /*         this.gerarTotalAdicional()
            this.somarTotalMaoObra() */
    /* this.gerarTotalOrcamentoBruto() */
    this.gerarDescontoTotal(data);
    /* this.gerarTotalDeducoes() */
    this.gerarDanosNaoCobertos(data);
    this.gerarPI1(data);
    this.gerarPI2(data);
    this.gerarPI3(data);
    this.gerarPI4(data);
    this.gerarPI5(data);
    this.gerarPI6(data);
    this.gerarPI7(data);
    this.gerarPI8(data);
    this.gerarTotalAdicional();
    this.somarTotalMaoObra();
    this.gerarTotalDeducoes();
    this.gerarTotalOrcamentoBruto();
    this.gerarTotalLiquido();
    this.gerarTotaisLiquidosPI();
    
  }
  gerarTotalPecas(dados) {
    var lista: Array<any> = new Array
    dados.forEach((peca) => {
      if (peca.troca == "true") {
        lista.push(peca)
      }
    })
    lista.forEach((data) => {
      this.total_pecas = this.soma(this.total_pecas, Number(data.valor_subtotal))
    })
  }

  gerarTotalAvarias(){
    this.avaria = Number(this.pi1_avaria) + Number(this.pi2_avaria) + Number (this.pi3_avaria) + Number(this.pi4_avaria) + Number(this.pi5_avaria) + Number(this.pi6_avaria) + Number(this.pi7_avaria) + Number(this.pi8_avaria)
  }

  gerarDanosNaoCobertos(dados) {
    var lista = new Array
    dados.forEach((peca) => {
      if (peca.tipo_orcamento == "Danos não cobertos") {
        lista.push(peca)
      }
    })
    lista.forEach((peca) => {
      this.deducao_dnc = this.soma(this.deducao_dnc, peca.valor_liquido)
    })
  }
  separarPecas(dados) {
    var lista: Array<any> = new Array
    lista = new Array
    dados.forEach((peca) => {
      if (peca.troca == "true" && peca.fornecimento_peca == "Original") {
        lista.push(peca)
      }
    })
    lista.forEach((data) => {
      //console.log('peca', data)
      this.peca_genuina = this.soma(this.peca_genuina, data.valor_subtotal)
    })

    lista = new Array
    dados.forEach((peca) => {
      if (peca.troca == "true" && peca.fornecimento_peca == "Paralela") {
        lista.push(peca)
      }
    })
    lista.forEach((data) => {
      this.peca_paralela = this.soma(this.peca_paralela, Number(data.valor_subtotal))
    })

    lista = new Array
    dados.forEach((peca) => {
      if (peca.troca == "true" && peca.fornecimento_peca == "CDV(Desmanche)") {
        lista.push(peca)
      }
    })
    lista.forEach((data) => {
      this.peca_desmanche = this.soma(this.peca_desmanche, Number(data.valor_subtotal))
    })
  }

  gerarTotalOrcamentoAvalido() {
    this.total_orcamento_av = (this.total_orcamento_b - (this.pecas_genuinas_f + this.pecas_paralelas_f + this.pecas_desmanche_f + this.valor_servicos_terceiro_f))

  }

  gerarDescontoTotal(dados) {
    dados.forEach((peca) => {
      this.pecas_descontos = this.soma(this.pecas_descontos, peca.valor_desconto)
    })
  }



  gerarTotalOrcamentoBruto() {
    this.total_orcamento_b = Number(this.mao_totalizador_valor) + Number(this.total_pecas) + Number(this.total_adicional)
    //console.log(this.total_orcamento_b)
    //console.log(this.mao_totalizador_valor, this.total_pecas, this.total_adicional)
    if (this.total_orcamento_b < 0) {
      this.total_orcamento_b = 0
    }
  }


  gerarTotalLiquido() {
    this.total_pecas_l = this.total_orcamento_b - (this.valor_deducoes + this.valor_divergencia)
    if (this.total_pecas_l < 0) {
      //alert('F')
      this.total_pecas_l = 0
    }
  }



  async gerarTabelasServicos(dados) {
    this.lista_troca = new Array
    this.lista_pintura = new Array
    this.lista_remocao_instalacao = new Array
    this.lista_reparacao = new Array
    this.lista_servico = new Array
    dados.forEach((peca) => {
      if (peca.pintura == 'true') {
        this.lista_pintura.push(peca)
      }
      if (peca.remocao_instalacao == 'true') {
        this.lista_remocao_instalacao.push(peca)
      }
      if (peca.reparo == 'true') {
        this.lista_reparacao.push(peca)
      }
      if (peca.troca == 'true') {
        this.lista_troca.push(peca)
        //console.log('lista', peca)
      }
      if (peca.tipo_servico_executado == 'Serviço') {
        this.lista_servico.push(peca)
      }
    })
  }

  gerarTotalAdicional() {
    this.lista_servico.forEach((data) => {
      this.total_adicional = this.soma(this.total_adicional, data.valor_total_bruto)
    })
  }

  gerarMaoObra(dados) {
    dados.forEach((dados) => {
      this.mao_valor_funilaria = dados.troca_valor_hora
      this.mao_valor_pintura = dados.pintura_valor_hora
      this.mao_valor_eletrica = dados.troca_valor_hora
      this.mao_valor_tapecaria = dados.troca_valor_hora
      this.mao_valor_vidracaria = dados.troca_valor_hora
      this.mao_valor_mecanica = dados.troca_valor_hora
      this.mao_valor_recuperacao = dados.reparo_valor_hora
      this.mao_valor_dados_remocao_inst = dados.remocao_instalacao_valor_hora
      if (dados.codigo_peca_ciclo == "FUNILARIA") {
        //calcular tempo
        let tempo_troca = dados.troca_tempo
        this.mao_hora_funilaria = (Number.isNaN(this.soma(this.mao_hora_funilaria, tempo_troca)) ? 0 : this.soma(this.mao_hora_funilaria, tempo_troca))
        //calcular valor total
        let novo = dados.troca_valor_servico
        this.mao_total_funilaria = this.soma(this.mao_total_funilaria, novo)
        //inclusao na lista de totalizadores
      }
      if (dados.pintura == "true") {
        //calcular tempo
        let tempo_troca = dados.pintura_tempo
        this.mao_hora_pintura = this.soma(this.mao_hora_pintura, tempo_troca)
        //atribuir valor hora

        //calcular valor total
        let novo = dados.pintura_valor_servico
        this.mao_total_pintura = this.soma(this.mao_total_pintura, novo)
      }
      if (dados.codigo_peca_ciclo == "ELÉTRICA") {
        //calcular tempo
        let tempo_troca = dados.troca_tempo
        this.mao_hora_eletrica = this.soma(this.mao_hora_eletrica, tempo_troca)
        //atribuir valor hora

        //calcular valor total
        let novo = dados.troca_valor_servico
        this.mao_total_eletrica = this.soma(this.mao_total_eletrica, novo)

      }
      if (dados.codigo_peca_ciclo == "TAPECARIA") {
        //calcular tempo
        let tempo_troca = dados.troca_tempo
        this.mao_hora_tapecaria = this.soma(this.mao_hora_tapecaria, tempo_troca)
        //atribuir valor hora

        //calcular valor total
        let novo = dados.troca_valor_servico
        this.mao_total_tapecaria = this.soma(this.mao_total_tapecaria, novo)
      }
      if (dados.codigo_peca_ciclo == "VIDRACARIA") {
        //calcular tempo
        let tempo_troca = dados.troca_tempo
        this.mao_hora_vidracaria = this.soma(this.mao_hora_vidracaria, tempo_troca)
        //atribuir valor hora

        //calcular valor total
        let novo = dados.troca_valor_servico
        this.mao_total_vidracaria = this.soma(this.mao_total_vidracaria, novo)

      }
      if (dados.codigo_peca_ciclo == "MECANICA") {
        //calcular tempo
        let tempo_troca = dados.troca_tempo
        this.mao_hora_mecanica = this.soma(this.mao_hora_mecanica, tempo_troca)
        //atribuir valor hora

        //calcular valor total
        let novo = dados.troca_valor_servico
        this.mao_total_mecanica = this.soma(this.mao_total_mecanica, novo)
      }
      if (dados.desc_tipo_serv_executado == "Serviço") {
        //calcular tempo
        let tempo_troca = dados.troca_tempo
        this.mao_hora_recuperacao = this.soma(this.mao_hora_recuperacao, tempo_troca)
        //atribuir valor hora

        //calcular valor total
        let novo = dados.troca_valor_servico
        this.mao_total_recuperacao = this.soma(this.mao_hora_recuperacao, novo)
      }
      if (dados.reparo == "true") {
        let tempo_reparo = dados.reparo_tempo
        this.mao_hora_recuperacao = this.soma(this.mao_hora_recuperacao, tempo_reparo)

        let novo = dados.reparo_valor_servico
        this.mao_total_recuperacao = this.soma(this.mao_total_recuperacao, novo)
      }
      if (dados.remocao_instalacao == "true") {
        let tempo_remocao_instalacao = dados.remocao_instalacao_tempo
        this.mao_hora_dados_remocao_inst = this.soma(this.mao_hora_dados_remocao_inst, tempo_remocao_instalacao)

        let novo = dados.remocao_inst_vl_servico
        this.mao_total_remocao_inst = this.soma(this.mao_total_remocao_inst, novo)
      }
    })
  }


  somarTotalMaoObra() {
    this.mao_totalizador_hora = (this.mao_hora_funilaria + this.mao_hora_dados_remocao_inst + this.mao_hora_eletrica + this.mao_hora_mecanica +
      this.mao_hora_pintura + this.mao_hora_recuperacao + this.mao_hora_tapecaria + this.mao_hora_vidracaria)

    this.mao_totalizador_valor = (this.mao_total_funilaria + this.mao_total_eletrica + this.mao_total_mecanica +
      this.mao_total_pintura + this.mao_total_recuperacao + this.mao_total_tapecaria + this.mao_total_vidracaria + this.mao_total_remocao_inst)

    /* //console.log(this.mao_total_funilaria, this.mao_total_eletrica, this.mao_total_mecanica,
      this.mao_total_pintura, this.mao_total_recuperacao, this.mao_total_tapecaria, this.mao_total_vidracaria, this.mao_total_remocao_inst) */
  }

  soma(atual, novo) {
    atual = Number(atual)
    novo = Number(novo)
    let total: number = (atual + novo)
    return total
  }
  calcularHoraCentesimal(horaStr: string): number {
    let horaCentesimal = 0
    if (horaStr) {
      let hora: number = Number(horaStr.split(':', 2)[0]);
      let minHot: number = Number(horaStr.split(':', 2)[1]) / 60.00;
      horaCentesimal = hora + minHot;
    }
    return horaCentesimal;
  }

  gerarTotaisLiquidosPI() {
    this.pi1_total_liquido = (this.pi1_total_bruto - this.pi1_desconto - this.franquia)
    this.pi2_total_liquido = (this.pi2_total_bruto - this.pi2_desconto)
    this.pi3_total_liquido = (this.pi3_total_bruto - this.pi3_desconto)
    this.pi4_total_liquido = (this.pi4_total_bruto - this.pi4_desconto)
    this.pi5_total_liquido = (this.pi5_total_bruto - this.pi5_desconto)
    this.pi6_total_liquido = (this.pi6_total_bruto - this.pi6_desconto)
    this.pi7_total_liquido = (this.pi7_total_bruto - this.pi7_desconto)
    this.pi8_total_liquido = (this.pi8_total_bruto - this.pi8_desconto)

  }

  //Atribuir valor da peca
  gerarPI1(dados) {
    let ponto1_list: Array<any> = new Array
    dados.forEach((data) => {
      if (data.pontos_impacto == 1) {
        ponto1_list.push(data)
      }
    })
    ponto1_list.forEach((dados) => {
      this.pi1_mao_obra = this.soma(this.pi1_mao_obra, dados.valor_servico)
      if (dados.troca == "true") {
        this.pi1_pecas = this.soma(this.pi1_pecas, dados.valor_subtotal)
      }
      if (dados.tipo_orcamento == "Avaria") {
        this.pi1_avaria = this.soma(this.pi1_avaria, dados.valor_total_bruto)
      }
      if (dados.tipo_servico_executado == 'Serviço') {
        this.pi1_adicional = this.soma(this.pi1_adicional, dados.valor_total_bruto)
      }
      this.pi1_desconto = this.soma(this.pi1_desconto, dados.valor_desconto)
      this.pi1_total_bruto = (this.pi1_mao_obra + this.pi1_pecas + this.pi1_adicional)
    })
  }

  gerarPI2(dados) {
    let ponto1_list: Array<any> = new Array
    dados.forEach((data) => {
      if (data.pontos_impacto == 2) {
        ponto1_list.push(data)
      }
    })
    ponto1_list.forEach((dados) => {
      this.pi2_mao_obra = this.soma(this.pi2_mao_obra, dados.valor_servico)
      if (dados.troca == "true") {
        this.pi2_pecas = this.soma(this.pi2_pecas, dados.valor_subtotal)
      }
      if (dados.tipo_orcamento == "Avaria") {
        this.pi2_avaria = this.soma(this.pi2_avaria, dados.valor_total_bruto)
      }
      if (dados.tipo_servico_executado == 'Serviço') {
        this.pi2_adicional = this.soma(this.pi2_adicional, dados.valor_total_bruto)
      }
      this.pi2_desconto = this.soma(this.pi2_desconto, dados.valor_desconto)
      this.pi2_total_bruto = (this.pi2_mao_obra + this.pi2_pecas + this.pi2_adicional)
    })
  }

  gerarPI3(dados) {
    let ponto1_list: Array<any> = new Array
    dados.forEach((data) => {
      if (data.pontos_impacto == 3) {
        ponto1_list.push(data)
      }
    })
    ponto1_list.forEach((dados) => {
      this.pi3_mao_obra = this.soma(this.pi3_mao_obra, dados.valor_servico)
      if (dados.troca == "true") {

      }
      this.pi3_pecas = this.soma(this.pi3_pecas, dados.valor_subtotal)
      if (dados.tipo_orcamento == "Avaria") {
        this.pi3_avaria = this.soma(this.pi3_avaria, dados.valor_total_bruto)
      }
      if (dados.tipo_servico_executado == 'Serviço') {
        this.pi3_adicional = this.soma(this.pi3_adicional, dados.valor_total_bruto)
      }
      this.pi3_desconto = this.soma(this.pi3_desconto, dados.valor_desconto)
      this.pi3_total_bruto = (this.pi3_mao_obra + this.pi3_pecas + this.pi3_adicional)
    })
  }

  gerarPI4(dados) {
    let ponto1_list: Array<any> = new Array
    dados.forEach((data) => {
      if (data.pontos_impacto == 4) {
        ponto1_list.push(data)
      }
    })
    ponto1_list.forEach((dados) => {
      this.pi4_mao_obra = this.soma(this.pi4_mao_obra, dados.valor_servico)
      this.pi4_pecas = this.soma(this.pi4_pecas, dados.valor_subtotal)
      if (dados.tipo_orcamento == "Avaria") {
        this.pi4_avaria = this.soma(this.pi4_avaria, dados.valor_total_bruto)
      }
      if (dados.tipo_servico_executado == 'Serviço') {
        this.pi4_adicional = this.soma(this.pi4_adicional, dados.valor_total_bruto)
      }
      this.pi4_desconto = this.soma(this.pi4_desconto, dados.valor_desconto)
      this.pi4_total_bruto = (this.pi4_mao_obra + this.pi4_pecas + this.pi4_adicional)
    })
  }

  gerarPI5(dados) {
    let ponto1_list: Array<any> = new Array
    dados.forEach((data) => {
      if (data.pontos_impacto == 5) {
        ponto1_list.push(data)
      }
    })
    ponto1_list.forEach((dados) => {
      this.pi5_mao_obra = this.soma(this.pi5_mao_obra, dados.valor_servico)
      this.pi5_pecas = this.soma(this.pi5_pecas, dados.valor_subtotal)
      if (dados.tipo_orcamento == "Avaria") {
        this.pi5_avaria = this.soma(this.pi5_avaria, dados.valor_total_bruto)
      }
      if (dados.tipo_servico_executado == 'Serviço') {
        this.pi5_adicional = this.soma(this.pi5_adicional, dados.valor_total_bruto)
      }
      this.pi5_desconto = this.soma(this.pi5_desconto, dados.valor_desconto)
      this.pi5_total_bruto = (this.pi5_mao_obra + this.pi5_pecas + this.pi5_adicional)
    })
  }
  gerarPI6(dados) {
    let ponto1_list: Array<any> = new Array
    dados.forEach((data) => {
      if (data.pontos_impacto == 6) {
        ponto1_list.push(data)
      }
    })
    ponto1_list.forEach((dados) => {
      this.pi6_mao_obra = this.soma(this.pi6_mao_obra, dados.valor_servico)
      this.pi6_pecas = this.soma(this.pi6_pecas, dados.valor_subtotal)
      if (dados.tipo_orcamento == "Avaria") {
        this.pi6_avaria = this.soma(this.pi6_avaria, dados.valor_total_bruto)
      }
      if (dados.tipo_servico_executado == 'Serviço') {
        this.pi6_adicional = this.soma(this.pi6_adicional, dados.valor_total_bruto)
      }
      this.pi6_desconto = this.soma(this.pi6_desconto, dados.valor_desconto)
      this.pi6_total_bruto = (this.pi6_mao_obra + this.pi6_pecas + this.pi6_adicional)
    })
  }
  gerarPI7(dados) {
    let ponto1_list: Array<any> = new Array
    dados.forEach((data) => {
      if (data.pontos_impacto == 7) {
        ponto1_list.push(data)
      }
    })
    ponto1_list.forEach((dados) => {
      this.pi7_mao_obra = this.soma(this.pi7_mao_obra, dados.valor_servico)
      this.pi7_pecas = this.soma(this.pi7_pecas, dados.valor_subtotal)
      if (dados.tipo_orcamento == "Avaria") {
        this.pi7_avaria = this.soma(this.pi7_avaria, dados.valor_total_bruto)
      }
      if (dados.tipo_servico_executado == 'Serviço') {
        this.pi7_adicional = this.soma(this.pi7_adicional, dados.valor_total_bruto)
      }
      this.pi7_desconto = this.soma(this.pi7_desconto, dados.valor_desconto)
      this.pi7_total_bruto = (this.pi7_mao_obra + this.pi7_pecas + this.pi7_adicional)
    })
  }

  gerarPI8(dados) {
    let ponto1_list: Array<any> = new Array
    dados.forEach((data) => {
      if (data.pontos_impacto == 8) {
        ponto1_list.push(data)
      }
    })
    ponto1_list.forEach((dados) => {
      this.pi8_mao_obra = this.soma(this.pi8_mao_obra, dados.valor_servico)
      this.pi8_pecas = this.soma(this.pi8_pecas, dados.valor_subtotal)
      if (dados.tipo_orcamento == "Avaria") {
        this.pi8_avaria = this.soma(this.pi8_avaria, dados.valor_total_bruto)
      }
      if (dados.tipo_servico_executado == 'Serviço') {
        this.pi8_adicional = this.soma(this.pi8_adicional, dados.valor_total_bruto)
      }
      this.pi8_desconto = this.soma(this.pi8_desconto, dados.valor_desconto)
      this.pi8_total_bruto = (this.pi8_mao_obra + this.pi8_pecas + this.pi8_adicional)
    })
  }

  generatePDF() {
    this.comu.filter('hide-bar');
    window.print();
    this.comu.filter('show-bar');
  }

  openVersao() {
    const initialState = {
      protocolo: this.protocolo
    };
    this.bsModalRef = this.modalService.show(VersoesComponent, { initialState, class: 'modal-dialog modal-xl' });
  }
}
