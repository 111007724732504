import { Component, Input, forwardRef, Injector, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EntrySelect } from '../../models/EntrySelect.model';
import { GenericInputFieldComponent } from '../generic-input-field/generic-input-field.component';

@Component({
  selector: 'app-select-field-search',
  templateUrl: './select-field-search.component.html',
  styleUrls: ['./select-field-search.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectFieldSearchComponent),
    multi: true
  }]
})
export class SelectFieldSearchComponent extends GenericInputFieldComponent {
  @Input() bindValue:string ="value";
  @Input() bindLabel:string ="label";
  @Input() itens: Array<EntrySelect>
  @Output() change = new EventEmitter();
  constructor(injector: Injector, private cdRef: ChangeDetectorRef) {
    super(injector);
  }

  onChange(event) {
    //console.log(event);
    this.change.emit(event);
  }

  public ngDoCheck() {
    //resolve: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after ...
    this.cdRef.detectChanges();
  }
}
