import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { EntityCiclo } from 'src/app/pages/veiculo/shared/model/entity-ciclo.model';

@Component({
  selector: 'generic-smart-search-field',
  templateUrl: './generic-smart-search-field.component.html',
  styleUrls: ['./generic-smart-search-field.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericSmartSearchFieldComponent implements OnInit {

  @Input('form-group') formGroup: FormGroup;
  @Input() label: string;
  @Input() showLabel: boolean = true;
  @Input() itens: Array<EntityCiclo>
  @Input() isReadOnly: boolean = false;
  @Output() change = new EventEmitter();

  currentValue;

  public noResultEntity: boolean = false;
  public showSpinner: boolean = false;

  constructor() { }

  ngOnInit(): void { }

  public onSelectEntity(event: TypeaheadMatch): void {
    this.formGroup.patchValue({
      'codigo': event.item.codigo,
      'descricao': event.item.descricao,
      'linkImage': event.item.linkImage
    });
  }

  public searchEntityNotResults(event: boolean): void {
    this.noResultEntity = event;    
  }

  public getPresentation(): string {
    return this.formGroup?.get('descricao').value;
  }

  getImage(): string {
    return this.formGroup?.get('linkImage').value;
  }

  showImage(): boolean {
    return this.getImage() != '';
  }

  onChange(event){
    //console.log(this.currentValue);
    //console.log("noTime")
    //console.log(this.formGroup.value);

    setTimeout(()=>{
      //console.log("timeout")
      //console.log(this.formGroup.value);
    }, 100);
    
    return;

    //change Selectected
    if(this.formGroup.get('descricao').value==""){
      //clean
      this.formGroup.patchValue({
        'codigo': null,
        'descricao': null,
        'linkImage': null
      })
    }
    this.change.emit(this.formGroup.value);
    
  }

}
