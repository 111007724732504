<nav *ngIf="displayMenu()" class="navbar navbar-expand-lg navbar-dark  mb-2 navDev">

  <button class="navbar-toggler" type="button" data-toggle="collapse"
   data-target="#navbarTogglerMenu" aria-controls="navbarTogglerMenu" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  
      <a class="navbar-link" routerLink="/orcamento">
        <img class="imgLogoDev" src="/assets/SigaLogo.png" alt="Siga">
      </a>
      
      

  <!-- <div class="collapse navbar-collapse" id="navbarTogglerMenu">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item">
        <a class="nav-link" routerLink="/persona" routerLinkActive="router-link-active">
          Link 1</a>
      </li>
    </ul>
  </div> -->

</nav>
