<div class="col-12 bg-color align-sidenavbar position-fixed_">
  <div class="d-flex flex-column align-items-center align-items-sm-start pt-1 text-white min-vh-100 w-100 min-w-100">
    <a href="/" class="d-flex align-items-center pt-2 pb-3 mb-1 link-dark text-decoration-none logo ">
      <img src="../../../assets/SigaParts_01.png" width="120" alt="">
    </a>

    <div class="input-group mb-3 px-2">
      <input class="form-control border-end-0 border" type="search" placeholder="Buscar" value="" id="search">
    </div>


    <ul id="menu" class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start">

      <li class="nav-item" *ngIf="checkInGrupo([1,2,3,4,5,6,7,8])">
        <a data-bs-toggle="collapse" class="nav-link menu px-0 align-middle">
          <button class=" btn btn-toggle align-items-center rounded collapsed" role="button" data-bs-toggle="collapse"
            data-bs-target="#home-collapse" aria-expanded="false">
            <i class="fa fa-user-plus" aria-hidden="true"></i>
            <span class="ms-1 d-sm-inline fw-bold">Cadastro</span>
          </button></a>
        <div class="collapse" id="home-collapse" data-bs-parent="#menu">
          <ul class="btn-toggle-nav list-group list-unstyled fw-normal pb-1 small">
            <li *ngIf="checkInGrupo([1,2,3,4]) && isMaster()"><a routerLink="/empresa/all"
                class="nav-link link-dark rounded">Empresas</a></li>
            <li *ngIf="checkInGrupo([1,2,3,4]) && (isCorporate() || isReguladora())"><a routerLink="/oficina/all" class="nav-link link-dark rounded">Oficinas</a></li>
            <li *ngIf="checkInGrupo([1,2,3,4]) && isCorporate()"><a routerLink="/reguladora/all"
                class="nav-link link-dark rounded">Reguladoras</a></li>
            <li *ngIf="checkInGrupo([1,2,3,4])"><a routerLink="/mao-de-obra/all" class="nav-link link-dark rounded">Serviços Adicionais</a></li>
            <!--    <li><a routerLink="/empresa/register" class="nav-link link-dark rounded">Cadastro de empresa</a></li>
                <li><a routerLink="/oficina/register" class="nav-link link-dark rounded">Cadastro de oficina</a></li>
                <li><a routerLink="/reguladora/register" class="nav-link link-dark rounded">Cadastro de reguladora</a></li>
                <li><a routerLink="/user/register" class="nav-link link-dark rounded">Cadastro de usuario</a></li>               
                <li><a routerLink="#" class="nav-link link-dark rounded">Cadastro de fornecedor</a></li> -->
            <li *ngIf="checkInGrupo([1,2,3,4,5,6,7,8])"><a routerLink="/orcamento/register"
                class="nav-link link-dark rounded">Orçamento</a></li>
          </ul>
        </div>
      </li>
      <li class="nav-item" *ngIf="checkInGrupo([1,2,3,4,5,6,7,8])">
        <a data-bs-toggle="collapse" class="nav-link menu px-0 align-middle">
          <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
            data-bs-target="#orcamento-collapse" aria-expanded="false">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-currency-exchange" viewBox="0 0 16 16">
              <path
                d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z" />
            </svg>
            <span class="ms-1 d-sm-inline fw-bold "> Orçamento</span>
          </button></a>
        <div class="collapse" id="orcamento-collapse" data-bs-parent="#menu">
          <ul class="btn-toggle-nav list-group list-unstyled fw-normal pb-1 small">
            <li *ngIf="checkInGrupo([1,2,3,4,5,6,7,8])"><a routerLink="/orcamento/todos" class="nav-link link-dark rounded">Todos orçamentos</a></li>
            <!--    <li><a routerLink="/orcamento/aprovado" class="nav-link link-dark rounded">Orçamentos aprovado</a></li>
                <li><a routerLink="/orcamento/negado" class="nav-link link-dark rounded">Orçamentos negado</a></li>
                <li><a routerLink="/orcamento/analise" class="nav-link link-dark rounded">Orçamentos em análise</a></li> -->
          </ul>
        </div>
      </li>

      <li class="nav-item" *ngIf="checkInGrupo([2,3,4])">
        <a data-bs-toggle="collapse" class="nav-link menu px-0 align-middle">
          <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
            data-bs-target="#config-collapse" aria-expanded="false">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-fill"
              viewBox="0 0 16 16">
              <path
                d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
            </svg>
            <span class="ms-1 d-sm-inline fw-bold ">Configuração</span>
          </button></a>
        <div class="collapse" id="config-collapse" data-bs-parent="#menu">
          <ul class="btn-toggle-nav list-group list-unstyled fw-normal pb-1 small">
            <li *ngIf="checkInGrupo([1,2,3,4])"><a routerLink="/user/all" class="nav-link link-dark rounded">Usuarios</a></li>
            <li *ngIf="checkInGrupo([2,3,4]) && isCorporate()"><a routerLink="/empresa/dados" class="nav-link link-dark rounded">Minha Empresa</a></li>
            <li *ngIf="checkInGrupo([2]) && isReguladora()"><a routerLink="/reguladora/dados" class="nav-link link-dark rounded">Minha Reguladora</a></li>
            <li *ngIf="checkInGrupo([2]) && isOficina()"><a routerLink="/oficina/dados" class="nav-link link-dark rounded">Minha Oficina</a></li>
            <!--
                <li><a href="#" class="nav-link link-dark rounded">Oficinas</a></li>
                <li><a href="#" class="nav-link link-dark rounded">Fornecedores</a></li>
                <li><a href="#" class="nav-link link-dark rounded">Informações Gerais</a></li>
                <li><a href="#" class="nav-link link-dark rounded">Empresa</a></li>
                -->
          </ul>
        </div>
      </li>

      <li class="nav-item" *ngIf="checkInGrupo([1,2,3,4,5,6,7,8])">
        <a data-bs-toggle="collapse" class="nav-link menu px-0 align-middle">
          <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
            data-bs-target="#account-collapse" aria-expanded="false">
            <svg xmlns="http://www.w3.org/200 0/svg" width="16" height="16" fill="currentColor" class="bi bi-person"
              viewBox="0 0 16 16">
              <path
                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
            </svg>
            <span class="ms-1 d-sm-inline fw-bold ">Conta</span>
          </button></a>
        <div class="collapse" id="account-collapse" data-bs-parent="#menu">
          <ul class="btn-toggle-nav list-group list-unstyled fw-normal pb-1 small">
            <!--
                <li><a routerLink="#" class="nav-link link-dark rounded">Perfil</a></li>
                <li><a routerLink="#" class="nav-link link-dark rounded">Configuração</a></li>
                -->
            <li><a href="javascript:void(0)" class="nav-link link-dark rounded" (click)="logoff()">Sair</a></li>
          </ul>
        </div>
      </li>
    </ul>

    <!--  
        <form [formGroup]="resourceForm" class="container-fluid px-0 box-current-empresa">
          <div class="row" style="padding:2px;"> 
            <div class="col-12">
              <app-select-field-search (change)="onChangeEmpresa($event)"  [itens]="listEmpresa" label="Perfil/Empresa" formControlName="currentEmpresa">
              </app-select-field-search>
            </div>
          </div>
        </form>
      -->
  </div>
</div>