import { Injectable, Optional } from "@angular/core";
import {
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { throwError as observableThrowError, Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

//import { LoginService } from "../../pages/login/shared/login.service";
import { AlertService } from "../alert/shared/alert.service";
import { Response } from "../../shared/models/response.model";
import { SessionService } from "src/app/shared/services/session.service";
import { AuthenticationCicloService } from '../authentication/authentication-ciclo.service';

// https://github.com/angular/angular/blob/master/packages/common/http/src/xhr.ts#L18
const XSSI_PREFIX = /^\)\]\}',?\n/;

@Injectable()
export class CstHttpInterceptor implements HttpInterceptor {
  constructor(
    private alertService: AlertService,
    private sessionService: SessionService,
    private authenticationCiclo: AuthenticationCicloService
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.responseType !== 'json') {
      return next.handle(req);
    }
    // convert to responseType of text to skip angular parsing
    req = req.clone({
      responseType: 'text'
    });

    return next.handle(req).pipe(
      tap((event) => {
        this.alertIfException(event);
      }),
      map(event => {
        if (!(event instanceof HttpResponse)) {
          return event;
        }
        return this.processJsonResponse(event);
      }),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            //this.authenticationCiclo.getToken().subscribe(token => this.sessionService.setCurrentToken(token));
          } else {
            let dto = this.dealWithLowLevelError(err);
            this.log(dto.message);
          }
        }
        return observableThrowError(err);
      })
    );
  }

  private processJsonResponse(res: HttpResponse<string>): HttpResponse<any> {
    let body = res.body;
    if (typeof body === 'string') {
      const originalBody = body;
      body = body.replace(XSSI_PREFIX, '');
      body = body !== '' ? JSON.parse(body, (key: any, value: any) => this.reviveUtcDate(key, value)) : null;
    }
    return res.clone({ body });
  }

  private reviveUtcDate(key: any, value: any): any {
    if (typeof value !== 'string') {
      return value;
    }
    if (value === '0001-01-01T00:00:00') {
      return null;
    }
    const match = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)Z?)$/.exec(value);
    if (!match) {
      return value;
    }
    return new Date(value);
  }

  alertIfException(event: HttpEvent<any>): void {
    if (event instanceof HttpResponse)
      if (event.body.response && event.body.response.hasException)
        this.log(event.body.response.exception);
  }

  dealWithLowLevelError(err: HttpErrorResponse): Response {
    let genericErrorMessageDto = new Response();
    //genericErrorMessageDto.hasException = true;

    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      this.log(err.error.message);
      genericErrorMessageDto.message =
        "Uma operação não foi concluída com sucesso. Consulte o log.";
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      this.log(`[${err.status}] ${err.message}`);
      genericErrorMessageDto.message = `[${err.status}] Uma operação não foi concluída com sucesso. Consulte o log.`;
    }
    return genericErrorMessageDto;
  }

  log(msg): void {
    this.alertService.error(`interceptor: ${msg}`); //TODO nao gostei de usar isso aqui mas nao consegui fazer de outro jeito
  }
}

/*
The err parameter to the callback above is of type HttpErrorResponse, and contains useful information on what went wrong.
There are two types of errors that can occur. If the backend returns an unsuccessful response code (404, 500, etc.), it gets returned as an error. Also, if something goes wrong client-side, such as an exception gets thrown in an RxJS operator, or if a network error prevents the request from completing successfully, an actual Error will be thrown.
In both cases, you can look at the HttpErrorResponse to figure out what happened.
*/
