<div class="row mb-4">
  <div class="col-md">
    <h1 class="h2 border-left pl-2">
      {{pageTitle}}
    </h1>
  </div>

  <div *ngIf="showButton" class="col-md text-right" >
    <a [routerLink]="buttonLink" class="btn" [ngClass]="buttonClass"> {{buttonText}} </a>
  </div>

</div>




