import { Component, forwardRef, Injector } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import {
  BsDatepickerConfig,
  BsDatepickerViewMode,
  BsLocaleService,
} from "ngx-bootstrap/datepicker";

import { defineLocale, ptBrLocale } from "ngx-bootstrap/chronos";
defineLocale('pt-br', ptBrLocale );

import { GenericInputFieldComponent } from "../generic-input-field/generic-input-field.component";

@Component({
  selector: "app-input-field-date-month-year",
  templateUrl: "./input-field-date-month-year.component.html",
  styleUrls: ["./input-field-date-month-year.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFieldDateMonthYearComponent),
      multi: true,
    },
  ],
})
export class InputFieldDateMonthYearComponent extends GenericInputFieldComponent {
  minMode: BsDatepickerViewMode = "month";
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(injector: Injector, private localseService: BsLocaleService) {
    super(injector);
    this.localseService.use('pt-br');
    this.bsConfig = Object.assign(
      {},
      {
        dateInputFormat: "MM/YYYY",
        minMode: this.minMode,
        useUtc: false,
      }
    );
  }
}
