<label [attr.for]="formControlName" class="form-label {{isRequired?'required':''}}" title="">{{ label }}</label>
<span
class="black c-pointer"
><i class="fa fa-info-circle" aria-hidden="true"></i></span>
<input
[type]="type"
class="form-control"
[id]="formControlName"
[formControl]="control"
[readonly]="isReadOnly"
[placeholder]="label"
mask ="{{mask}}"
[tabindex]="isReadOnly?'-1':tabindex"
[maxlength]="maxlength"
title=""
>
<form-field-error [form-control]="control"></form-field-error>