import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../loader.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',  
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent { 
 public mensagem: string;
 public isLoading: Subject<boolean> = this.loaderService.isLoading;

  constructor(private loaderService: LoaderService) {
    //get text mensaem para display
    this.loaderService.mensagem.subscribe(
      data => {
        //nova mensagem
        this.mensagem = data;
      });

   // this.loaderService.show();
   }


}
