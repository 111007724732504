<label [attr.for]="formControlName" class="form-label {{isRequired()?'required':''}}">{{ label }}</label>
<input
[type]="type"
class="form-control"
[id]="formControlName"
[formControl]="control"
[readonly]="isReadOnly"
[placeholder]="label"
mask ="{{mask}}"
[tabindex]="isReadOnly?'-1':tabindex"
[maxlength]="maxlength"
>
<form-field-error [form-control]="control"></form-field-error>