import { EntrySelect } from './../models/EntrySelect.model';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UserSession } from '../models/user-session.model';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SessionService { 

  private readonly keyCurrentSession = 'orcamentacao-current-session';
  private sessionSubject = new Subject<UserSession>();
  public isLogado = new Subject<boolean>();

  setCurrentSession(sessionDto: UserSession): void {
    localStorage.setItem(this.keyCurrentSession, JSON.stringify(sessionDto));
    this.sessionSubject.next(sessionDto);
    /*if(sessionDto.logado){
      this.isLogado.next(true);
    }else{
      this.isLogado.next(false);
    }*/
  }

  setCurrentSinistro(idSinistro: number): void {
    let userSession = this.getUserSession();
    //userSession.idSinistro = idSinistro;
    this.setCurrentSession(userSession);
  }

  setCurrentTokenCiclo(token: string) {
    let userSession = this.getUserSession();
    userSession.TokenCiclo = token;
    this.setCurrentSession(userSession);
  }

  setCurrentToken(token: string) {
    let userSession = this.getUserSession();
    userSession.token = token;
    this.setCurrentSession(userSession);
  }

  getCurrentToken() {
    return this.getUserSession().token;
  }

  setSecretKey(secret: string) {
    let userSession = this.getUserSession();
    userSession.secretKey = secret;
    this.setCurrentSession(userSession);
  }

  getUUIDEmpresa() {
    return this.getUserSession().uuid_empresa;
  }

  getSecretKey() {
    return this.getUserSession().secretKey;
  }
  getApiAccessKey() {
    return this.getUserSession().ApiAccessKey;
  }

  getCargo() {
    return this.getUserSession().grupo_id
  }

  setEmail(value: string) {
    localStorage.setItem(this.keyCurrentSession + "_email", value);
  }

  getEmail() {
    const value = localStorage.getItem(this.keyCurrentSession + "_email");
    if (value) {
      return value;
    }
    return "";
  }

  getRazaoSocial() {
    let i = this.getUserSession();
    return i.razaoSocial;
  }

  getTipoEmpresa() {
    let i = this.getUserSession();
    return i.tipo_empresa;
  }

  getUserSession(): UserSession {
    let userSession: UserSession;
    if (this.hasCurrentSession()) {
      userSession = this.getCurrentSession();
    } else {
      userSession = new UserSession();
    }
    //check isLogado
    if (userSession.logado) {
      this.isLogado.next(true);
    } else {
      this.isLogado.next(false);
    }

    return userSession;
  }

  tipoEmpresa(): string {
    return this.getUserSession().tipo_empresa;
  }

  hasCurrentSession(): boolean {
    return localStorage.getItem(this.keyCurrentSession) !== null;
  }

  getCurrentSession(): UserSession {
    return JSON.parse(localStorage.getItem(this.keyCurrentSession));
  }

  async clear() {
    await localStorage.removeItem(this.keyCurrentSession);
    this.sessionSubject.next(new UserSession);
    this.isLogado.next(false);
  }

  getSessionSubject() {
    return this.sessionSubject;
  }

  isAuthenticated() {
    return this.getUserSession().logado;
  }

  //profile currentEmpresa
  getCurrentEmpresa() {
    return this.getUserSession().currentEmpresa;
  }
  
  setCurrentEmpresa(value: string) {
    let userSession = this.getUserSession();
    userSession.currentEmpresa = value;
    this.setCurrentSession(userSession);
  }

  getCurrentSecretKeyEmpresa() {
    let current = this.getCurrentEmpresa();
    if (current) {
      return current.secretKey;
    }
  }

  getCurrentRazaoSocial() {
    let current = this.getCurrentEmpresa();
    if (current) {
      return current.razaoSocial;
    }
  }

  checkInGrupo(grupoIds:Array<any> = []) {
    const grupoId = this.getCurrentSession()?.grupo_id;
    //in grupo?
    if (grupoId != undefined && grupoIds.length > 0 && grupoIds.includes(Number.parseInt(grupoId))) {
      return true;
    }
    return false;
  }

  isMaster(){
    return environment.empresa_master == this.getSecretKey();
  }


}
