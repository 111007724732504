import { Component, Input, forwardRef, Injector, ChangeDetectorRef   } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ProgressBarComponent),
    multi: true
  }]
})
export class ProgressBarComponent {

  @Input() width:string="0";
  @Input() background:string="";
  @Input() v:string

  constructor(injector: Injector,
    private cdRef: ChangeDetectorRef
) {}

public ngDoCheck() {
this.cdRef.detectChanges();
}
  ngOnInit(): void {
  }

}
