import { RestService } from './rest.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionService } from './session.service';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrcamentoService extends RestService {

  constructor(protected http: HttpClient,
    protected session: SessionService,
    protected router: Router
  ) {
    super(http, session, router);
  }

  getOrcamentos(postData): any {
    return this.http.post(this.ws + "wsOrcamentos.rule?sys=API", postData)
  }

  getOrcamentoProtocol(protocol: any): any {
    let postData = {
      "method": "GET",
      "type": "protocol",
      "protocol": protocol
    }
    return this.http.post(this.ws + "wsOrcamentos.rule?sys=API", postData)
  }

  getOrcamentoProtocolEdicao(protocol: any): any {
    let postData = {
      "method": "GET",
      "type": "edicao",
      "protocol": protocol
    }
    return this.http.post(this.ws + "wsOrcamentos.rule?sys=API", postData)
  }

  getOficina(uuid) {
    let postData = {
      "method": "GET",
      "client": {
        "taxIdentifier": {
          "uuid": uuid
        }
      }
    }
    return this.http.post(this.ws + "wsEmpresa.rule?sys=API", postData)
  }

  getOficinaByID(id) {
    let postData = {
      "method": "GET",
      "client": {
        "taxIdentifier": {
          "id": id
        }
      }
    }
    return this.http.post(this.ws + "wsEmpresa.rule?sys=API", postData)
  }

  getDadosOrcamentoUUID(uuid) {
    let postData = {
      "method": "GET",
      "type": "uuid",
      "uuid": uuid
    }
    return this.http.post(this.ws + "wsOrcamentos.rule?sys=API", postData)
  }

  saveObservecao(postData) {
    return this.http.put(this.ws + "wsOrcamentos.rule?sys=API", postData)
  }

  saveItens(postData: any): any {
    //update?
    if (postData.itens && postData.itens.length && postData.itens[0].uuidItem != null) {
      return this.http.put(this.ws + "wsOrcamentosItens.rule?sys=API", postData)
    } else {
      return this.http.post(this.ws + "wsOrcamentosItens.rule?sys=API", postData)
    }
  }
  salvaValores(postData){
    return this.http.put(this.ws + "wsOrcamentosItens.rule?sys=API", postData)
  }
  updateItems(uuidOrcamento: string, itens: Array<any>, status: string) {
    let postData = {
      "uuidOrcamento": uuidOrcamento,
      "tipo": status,
      "itens": itens
    }
    return this.http.put(this.ws + "wsOrcamentosItens.rule?sys=API", postData)
  }

  recalculoTmo(postData) {
    return this.http.post(this.ws + "wsRecalculoTmo.rule?sys=API", postData)
  }

  deleteItem(uuidOrcamento: string, uuidItem: string): any {
    let postData = {
      "method": "DELETE",
      "uuidOrcamento": uuidOrcamento,
      "uuidItem": uuidItem
    }
    return this.http.post(this.ws + "wsOrcamentosItens.rule?sys=API", postData);
  }

  deleteItems(uuidOrcamento: string, arrayUuidItens: Array<string>): any {
    let postData = {
      "method": "DELETE",
      "flag": "mult",
      "uuidOrcamento": uuidOrcamento,
      "itens": arrayUuidItens
    }
    return this.http.post(this.ws + "wsOrcamentosItens.rule?sys=API", postData);
  }

  getUsuarioResponsavelUUIDEmp(uuid, todos = false) {
    let postData = {
      "method": "GET",
      "type": "GET_usuario_uuid_empresa",
      "uuid": uuid,
      "all": todos
    }
    return this.http.post(this.ws + "wsAtribuicao.rule?sys=API", JSON.stringify(postData))
  }

  getUsersByTipoEmpresa(postData: any) {
    return this.http.post(this.ws + "wsAtribuicao.rule?sys=API", JSON.stringify(postData))
  }

  getItens(protocolo: string): any {
    let postData = {
      "method": "GET",
      "type": "orcamento",
      "protocolo": protocolo,
      "limit": "1000",
      "offset": "1"
    };
    return this.http.post(this.ws + "wsOrcamentosItens.rule?sys=API", postData);
  }
  getItensByUUID(uuid: string): any {
    let postData = {
      "method": "GET",
      "type": "orcamento",
      "uuidOrcamento": uuid,
      "limit": "1000",
      "offset": "1"
    };
    return this.http.post(this.ws + "wsOrcamentosItens.rule?sys=API", postData);
  }

  getItensVersaoProtocolo(protocolo: string): any {
    let postData = {
      "method": "GET",
      "type": "versao",
      "protocolo": protocolo
    };
    return this.http.post(this.ws + "wsOrcamentosItens.rule?sys=API", postData);
  }

  saveValidacaoVeiculo(postData: any): any {
    return this.http.put(this.ws + "wsOrcamentos.rule?sys=API", postData)
  }


  //array images:  [{index: 1, tipo:"png", imagem: "base_64"}]
  saveImages(uuidOrcamento: string, images: any): any {
    let postData = {
      "uuidOrcamento": uuidOrcamento,
      "itens": images
    }
    return this.http.post(this.ws + "wsOrcamentosImagens.rule?sys=API", postData)
  }

  deleteImage(uuidOrcamento: string, uuidImage: string): any {
    let postData = {
      "method": "DELETE",
      "uuidOrcamento": uuidOrcamento,
      "uuidImagem": uuidImage
    }
    return this.http.post(this.ws + "wsOrcamentosImagens.rule?sys=API", postData);
  }

  deleteMultImage(uuidOrcamento: string, listaImgs: any) {
    let postData = {
      "method": "DELETE",
      "type": "mult",
      "uuidOrcamento": uuidOrcamento,
      "listaImagens": [listaImgs]
    }
    return this.http.post(this.ws + "wsOrcamentosImagens.rule?sys=API", postData);
  }

  multDelete(uuidOrcamento: string): any {//ex identificadores "identificador1", "identificador2", "identificador3",..."
    let postData = {
      "method": "DELETE",
      "uuidItem": uuidOrcamento
    }
    return this.http.post(this.ws + "wsOrcamentosItens.rule?sys=API", postData);
  }

  getImages(uuidOrcamento: string): any {
    let postData = {
      "method": "GET",
      "type": "orcamento",
      "uuidOrcamento": uuidOrcamento,
      "limit": "100",
      "offset": "1"
    }
    return this.http.post(this.ws + "wsOrcamentosImagens.rule?sys=API", postData);
  }

  saveModal(lista: any, fornecedor: any, desconto: any, protocolo: any) {//ex identificadores: "identificador1, identificador2, identificador3,..."
    let postData = {
      "identificadores": [lista],
      "itens": {
        "fornecedor": fornecedor,
        "desconto": desconto,
      },
      "protocolo": protocolo
    }
    return this.http.put(this.ws + "wsOrcamentosItens.rule?sys=API", postData);
  }

  saveFornecedorItem(uuidOrcamento: string, itens: Array<any>) {
    let postData = {
      "flag": "mult",
      "uuidOrcamento": uuidOrcamento,
      "itens": itens
    }
    return this.http.put(this.ws + "wsOrcamentosItens.rule?sys=API", postData);
  }

  saveDescontoItem(uuidOrcamento: string, itens: Array<any>) {
    let postData = {
      "flag": "mult",
      "uuidOrcamento": uuidOrcamento,
      "itens": itens
    }
    return this.http.put(this.ws + "wsOrcamentosItens.rule?sys=API", postData);
  }

  getResumo(): any {
    let postData = {
      "method": "GET"
    }
    return this.http.post(this.ws + "wsOrcamentosResumo.rule?sys=API", postData);
  }
}

