import { EmpresaListComponent } from './pages/empresa/empresa-list/empresa-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService, grupo, tipo } from './guards/auth-guard.service';
import { RelatorioComponent } from './pages/versoes/relatorio/relatorio.component';
import { ComparacaoComponent } from './pages/versoes/comparacao/comparacao.component';


const routes: Routes = [

  { path: '', redirectTo: 'orcamento/todos', pathMatch: 'full' },

  {
    path: 'veiculo',
    loadChildren: () => import('./pages/veiculo/veiculo.module').then(m => m.VeiculoModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'empresa',
    loadChildren: () => import('./pages/empresa/empresa.module').then(m => m.EmpresaModule),
    canActivate: [AuthGuardService],
    data: { roles: [grupo.SUPERADMIN, grupo.SUPORTE, grupo.ADMINISTRADOR],
            rolesTipo: [tipo.CORPORATE]
          }
  },
  /*
  {
    path: 'cadastrarorcamento',
    loadChildren: () => import('./pages/registerbudget/registerbudget.module').then(m => m.RegisterbudgetModule),
    canActivate:[AuthGuardService]
  },*/
  {
    path: 'user',
    loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuardService],
    data: {roles: [grupo.SUPERADMIN, grupo.SUPORTE, grupo.ADMINISTRADOR]}
  },
  {
    path: 'relatorio/versao/:protocolo/:versao',
    component:RelatorioComponent,
    canActivate: [AuthGuardService],
    data: {roles: [grupo.SUPERADMIN, grupo.SUPORTE, grupo.ADMINISTRADOR]}
  },
  {
    path: 'relatorio/comparacao/:protocolo/:versao',
    component:ComparacaoComponent,
    canActivate: [AuthGuardService],
    data: {roles: [grupo.SUPERADMIN, grupo.SUPORTE, grupo.ADMINISTRADOR]}
  },
  {
    path: 'oficina',
    loadChildren: () => import('./pages/oficina/oficina.module').then(m => m.OficinaModule),
    canActivate: [AuthGuardService],
    data: {roles: [grupo.SUPERADMIN, grupo.SUPORTE, grupo.ADMINISTRADOR, grupo.REGULADOR, grupo.ANALISTA, grupo.ORCAMENTISTA]}
  },
  {
    path: 'reguladora',
    loadChildren: () => import('./pages/reguladora/reguladora.module').then(m => m.ReguladoraModule),
    canActivate: [AuthGuardService],
    data: {roles: [grupo.SUPERADMIN, grupo.SUPORTE, grupo.ADMINISTRADOR]}
  },
  {
    path: 'mao-de-obra',
    loadChildren: () => import('./pages/mao-de-obra/mao-de-obra.module').then(m => m.MaoDeObraModule),
    canActivate: [AuthGuardService],
    data: {roles: [grupo.SUPERADMIN, grupo.SUPORTE, grupo.ADMINISTRADOR, grupo.REGULADOR, grupo.ANALISTA, grupo.ORCAMENTISTA]}
  },
  {
    path: 'orcamento',
    loadChildren: () => import('./pages/orcamento/orcamento.module').then(m => m.OrcamentoModule),
    canActivate: [AuthGuardService]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
