import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import { NgModule } from "@angular/core";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MessageService } from 'src/app/shared/message.service';
import { RestService } from 'src/app/shared/services/rest.service';
import { OrcamentoService } from 'src/app/shared/services/orcamento.service';
import { stringify } from 'querystring';

@Component({
  selector: 'app-versoes',
  templateUrl: './versoes.component.html',
  styleUrls: ['./versoes.component.css']
})
export class VersoesComponent implements OnInit {
  itens: Array<any>;
  comparacao: Array<any>;
  tab_active: any = 1;
  versao: any = null;
  listaVersoes: Array<any> = [];
  constructor(
    public bsModalRef: BsModalRef,
    private rest: RestService,
    private info: MessageService,
    private routes: Router,
    private ActivatedRoute: ActivatedRoute,
    private orcamento: OrcamentoService
  ) { }
  protocolo: any;
  ngOnInit(): void {
    /* this.rest.criarVersao(this.protocolo, 13).subscribe((data: any) => {
      console.log(data)
    }) */
    this.rest.getVersoes(this.protocolo).subscribe((data: any) => {
      //console.log(data.data.versoes)
      if (data.status != 'erro') {
        //console.log(data.data)
        this.itens = data.data.versoes
        this.comparacao = data.data.versoes
      }
      else if (data.data == 'orcamento nao encontrado')
        this.info.showErro('Não existem versões para o orçamento')
      console.log(this.itens)
      console.log(this.comparacao)
    })
  }

  retornaClass(t) {
    if (t == this.tab_active)
      return 'btn-active'
    return ''
  }

  selectMultVersao(v) {
    let index = this.listaVersoes.indexOf(v)
    if (index == -1) {
      let t = this.listaVersoes.length
      this.listaVersoes[t] = v
    }
    else {
      this.listaVersoes.splice(index, 1);
    }
  }

  selectVersao(v) {
    this.versao = v;
  }

  totab(t) {
    this.tab_active = t;
  }

  btnDisable() {
    if (this.tab_active == 1) {
      return (this.versao == null)
    }
    else {
      return (this.listaVersoes.length == 0)
    }
  }

  retornaStyle(t) {
    if (t == this.tab_active)
      return 'display:block'
    return 'display:none'
  }

  onSubmit() {
    if (this.tab_active == 1) {
      this.routes.navigate(['/relatorio/versao', this.protocolo, this.versao], { relativeTo: this.ActivatedRoute })
    }
    else {
      let v = '';
      this.listaVersoes.sort((a, b) => a - b)
      for (let i = 0; i < this.listaVersoes.length; i++) {
        v = v + this.listaVersoes[i] + ','
      }
      v.substring(0, v.length - 1)
      this.routes.navigate(['/relatorio/comparacao', this.protocolo, v.substring(0, v.length - 1)], { relativeTo: this.ActivatedRoute })
    }
    this.bsModalRef.hide();
  }
}
