import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { empty, Observable, throwError } from "rxjs";
import { catchError, finalize, map, retry, take } from "rxjs/operators";
import { LoaderService } from "../loader.service";
import { SessionService } from "../services/session.service";
import { MessageService } from "../message.service";
import { environment } from './../../../environments/environment';
@Injectable()
export class LoaderInterceptor implements HttpInterceptor{

   constructor(public loadService: LoaderService,
               private sessionService: SessionService,
               private info: MessageService,
               private router: Router){}
   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {     
       //url request
       let url = req.url;

       //request API sigaParts? ========================================
       if(url.includes(environment.apiSigaParts)){
          //show loading
          this.loadService.show();

          //get dados da sessão
          const userSession = this.sessionService.getUserSession();
          //header autenticação ========================================
          
          //default secretKey global
          let secretKey = environment.secretKeyGlobal;
          let apiAccessKey = this.sessionService.getSecretKey();

          //serviços globais [utiliza secretKey Global]
          let servicoGlobal = url.includes('wsEmpresaStatus.rule') || 
                                url.includes('wsBuscarUF.rule') || 
                                url.includes('wsIdentificadorEmpresa.rule') || 
                                url.includes('wsBuscarCidades.rule')
          
          //serviços vinculado ao profile selecionado (secretKey do profile)
          let allowProfile =    url.includes('wsOrcamentosResumo.rule') ||
                                url.includes('wsOrcamentos.rule') ||
                                url.includes('wsUsuarios.rule') ||
                                url.includes('wsMaoDeObra.rule') ||
                                url.includes('wsOrcamentosItens.rule') 
          
          //desativa global secretKey
          if(url.includes('noGlobal')){
            allowProfile = true;
            //servicoGlobal = false;
          }                      
          
          if(!servicoGlobal){
            //secretKey do login/usuario logado
            secretKey = this.sessionService.getSecretKey();
          }

          //change secretKey for current profile selected
          const currentSecretKey = this.sessionService.getCurrentSecretKeyEmpresa();
          if(currentSecretKey && !servicoGlobal && allowProfile){
            secretKey = currentSecretKey;
            apiAccessKey = currentSecretKey;
          }
          //alert(url+"\rn"+secretKey);

          const headers = new HttpHeaders({                  
                  //'Secret-Key': environment.secretKeyGlobal,
                  'Secret-Key': secretKey,
                  'Api-Access-Key': apiAccessKey,
                  'Content-Type': 'application/json;charset=UTF-8',
                  'Transaction-Hash': this.sessionService.getCurrentToken()
          });
          //atualização request com headers
          req = req.clone({headers}); 
          //return request and hide loading
          return next.handle(req).pipe(
            map(resp => {
            
              if (resp instanceof HttpResponse) {
                  //console.log(resp.body.status);
                  //verifica o resultado
                  let body = JSON.parse(resp.body);
                  //console.log(body);
                  //console.log(body);
                  //body.status "error"
                  if(body.status =='error'){
                    //mensagem de error
                    let messageError = body.data;
                    //show error
                    this.info.showErro(messageError).then(data =>{
                      if(body.data =='Token inválido'){
                        this.sessionService.clear();//clear session
                        this.router.navigate(['/login/login-form']);
                      }
                    })
                    //return;
                  }

                  //return somente Data (mantem padrão de retorno api siglo)
                  if(body.status =='success' && url.includes('onlyData')){
                    return  resp.clone({ body: body.data });
                  }
                  else{
                    return  resp;
                  }
                  

              
            }
            }),
            finalize( ()=> {
              //oculta loading
              this.loadService.hide()
            }),
            //retry(1),
            //take(1),
            catchError((error: HttpErrorResponse) => {
              //log error
              //console.log(error)
              //status error
              let status = error.status;
              //exibe erro
              this.info.showErro(error.message)
              //gera error
              return throwError(error);
            })
          );
    } else {
      return next.handle(req);
    }

   }



}
