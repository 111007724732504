import { Component, OnInit } from '@angular/core';

import { AlertService } from '../shared/alert.service';

@Component({
  selector: 'app-alert-form',
  templateUrl: './alert-form.component.html',
  styleUrls: ['./alert-form.component.css']
})
export class AlertFormComponent implements OnInit {

  message: any;

  constructor(private alertService: AlertService) { }

  ngOnInit(): void {
    this.alertService.getMessage().subscribe(message => { this.message = message; });
  }

}
