import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { SessionService } from '../../shared/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate{

  constructor(
    private router: Router,
    private session: SessionService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) : boolean {

    let path = route.routeConfig.path;
   //console.log(route);

    if (this.session.isAuthenticated()){
      return true;
    }
    this.router.navigate(['/login/login-form']);
    //TODO para onde redirecionar
    //this.router.navigate(['/login'], { queryParams: { retUrl: route.url }});

    

  }

}