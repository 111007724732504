import { EntrySelect } from './../models/EntrySelect.model';
import { Component, OnInit } from '@angular/core';
import { SessionService } from '../services/session.service';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { RestService } from '../services/rest.service';
import { tipo } from '../../guards/auth-guard.service';
declare var $: any;

@Component({
  selector: 'app-sidenavbar',
  templateUrl: './sidenavbar.component.html',
  styleUrls: ['./sidenavbar.component.css']
})
export class SidenavbarComponent {
  public logado: Subject<boolean> = this.session.isLogado;
  listEmpresa: Array<EntrySelect>;
  resourceForm:FormGroup; 
  constructor(private session: SessionService, 
              private router: Router,
              private formBuilder: FormBuilder,
              private rest:RestService) {

              //validators  
              this.resourceForm = this.formBuilder.group({
                currentEmpresa: [null, []]
              });
              this.loadEmpresas();
			  this.onSearch();
           
            
  }
  
  onSearch(){
	$(document).ready(function () {  
		//search on menu	
		$('#search').bind("input", function() { 
		var value = $('#sidebar #search').val();
		value = value.toLowerCase().trim();
		value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
		
		if(value.length>0){
			//open all menus
			$('#sidebar .nav-item .collapse').addClass('show');
			//hide title menu
			$('#sidebar .nav-item .menu').hide();
			//$('#sidebar .nav-item').css('margin-bottom','.5em');
			$('#sidebar .nav-item').css('margin-bottom','0');
		}else{
			//close all menus
			$('#sidebar .nav-item .collapse').removeClass('show');
			//open first menu
			//$('#sidebar .nav-item .collapse:first-child').addClass('show');
			//show title all menu
			$('#sidebar .nav-item .menu').show();
			//restore margin-bottom
			$('#sidebar .nav-item').css('margin-bottom','.5em');
		}		
		$("#sidebar .nav-item .nav-link:not('.menu')").show().filter(function() {
			var texto = $(this).text().toLowerCase().trim();
			texto = texto.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
			return texto.indexOf(value) == -1;		
		  }).hide();
		});
	});  
	  
  }
  
  loadEmpresas(){
    return ;
    this.rest.loadEmpresas().subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        let itens = response.data;
        let dados: Array<EntrySelect> = [];
        itens.forEach(item => {            
              dados.push({'value':item.cnpj+":"+item.secret_key,'label':item.razao_social});
        });
        this.listEmpresa = dados;
        //console.log(this.listEmpresa)
        //console.log(this.session.getCurrentEmpresa());
        this.resourceForm.get('currentEmpresa')?.setValue(this.session.getCurrentEmpresa());      
      });    

  }

  logoff(){
    this.session.clear();
    this.router.navigate(['/login/login-form']);
  }

  
  onChangeEmpresa(event){ return;
    let current = this.resourceForm.get('currentEmpresa')?.value;  
    this.session.setCurrentEmpresa(current); 
    this.router.navigate(['/home']);   
  }

  checkInGrupo(grupoIds=[]){
    return this.session.checkInGrupo(grupoIds);
  }

  isMaster(){
    return this.session.isMaster();
  }

  isCorporate(){
    let tipo = this.session.getTipoEmpresa();
    return tipo=='CORPORATE';
  }

  isReguladora(){
    let tipo = this.session.getTipoEmpresa();
    return tipo=='REGULADORA';
  }

  isOficina(){
    let tipo = this.session.getTipoEmpresa();
    return tipo=='OFICINA';
  }

  
}
