<div class="modal-dialog modal-xl mt-0 mb-0" role="document">
    <div class="modal-content">
        <div class="modal-body">
            <button type="button" aria-label="Close" (click)="bsModalRef.hide()" class="btn-close btn-danger float-end"
                data-bs-dismiss="modal" aria-label="Close">
            </button>
            <div class="col-lg-12 col-md-12 col-sm-12 mb-4">
                <h4 class="titulo_seccao">Relatórios</h4>
            </div>
            <div class="col-12 d-flex body">
                <div class="side-bar col-12">
                    <ul class="col-12">
                        <li [class]="retornaClass(1)" (click)="totab(1)">
                            <div class="option"><button (click)="totab(1)">Orçamento</button></div>
                        </li>
                        <li [class]="retornaClass(2)" (click)="totab(2)">
                            <div class="option"><button (click)="totab(2)">Comparação</button></div>
                        </li>
                    </ul>
                </div>
                <div class="col-12 d-flex abas">
                    <div class="content col-12" [style]="retornaStyle(1)">
                        <div class="col-12 d-flex justify-content-center">
                            <h4>Selecione a versão desejada para o relatório</h4>
                        </div>
                        <table class="table">
                            <thead>
                                <tr>
                                    <td scope="col" class="td_th"></td>
                                    <td scope="col" class="td_th">Revisão</td>
                                    <td scope="col" class="td_th">Data</td>
                                    <td scope="col" class="td_th">Responsável</td>
                                    <td scope="col" class="td_th">Status</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of itens, let i = index">
                                    <td><input type="radio" class="form-check-input" name="select"
                                            (click)="selectVersao(item.versao)">
                                    </td>
                                    <td>{{item.versao}}</td>
                                    <td>{{item.data}}</td>
                                    <td>{{(item.dados.nome_usuario_atribuicao == null)?
                                        item.dados.solicitante:item.dados.nome_usuario_atribuicao}}</td>
                                    <td>{{item.status}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="content col-12" [style]="retornaStyle(2)">
                        <div class="col-12 d-flex justify-content-center">
                            <h4>Selecione os orçamentos que deseja comparar</h4>
                        </div>
                        <table class="table">
                            <thead>
                                <tr>
                                    <td scope="col" class="td_th"></td>
                                    <td scope="col" class="td_th">Revisão</td>
                                    <td scope="col" class="td_th">Data</td>
                                    <td scope="col" class="td_th">Responsável</td>
                                    <td scope="col" class="td_th">Status</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let comp of comparacao, let i = index">
                                    <td><input type="checkbox" name="select" class="form-check-input"
                                            (click)="selectMultVersao(comp.versao)"></td>
                                    <td>{{comp.versao}}</td>
                                    <td>{{comp.data}}</td>
                                    <td>{{(comp.dados.nome_usuario_atribuicao == null)?
                                        comp.dados.solicitante:comp.dados.nome_usuario_atribuicao}}</td>
                                    <td>{{comp.status}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footerr">
                <button type="button" class="btn btn-primary" (click)="onSubmit()"
                    [disabled]="btnDisable()">Confirmar</button>
                <button id="closeModal" type="button" class="btn btn-danger" data-bs-dismiss="modal"
                    (click)="bsModalRef.hide()">Cancelar</button>
            </div>
        </div>
    </div>
</div>