import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

import { TokenCiclo } from '../../shared/models/token-ciclo.model';
import { SessionService } from '../../shared/services/session.service';
import { UserLoginCiclo } from '../../shared/models/user-login-ciclo.model';
import { UserSession } from '../../shared/models/user-session.model';

@Injectable()
export class AuthenticationCicloService {

  private urlApiCiclo: string;

  constructor(
    private http: HttpClient,
    private session: SessionService
  ) {
    this.urlApiCiclo = environment.apiCicloUrl;
  }

  getToken(): Observable<string> {
    // let userSession: UserSession = new UserSession();
    // if (this.session.hasCurrentSession()) {
    //   userSession = this.session.getCurrentSession();
    // }
    let urlAuthentication = `${this.urlApiCiclo}Account/login`
    let login: UserLoginCiclo = this.getUserCiclo();
    let httpHead: HttpHeaders = this.getheaders();
    let options = { headers: httpHead, withCredentials: true };
    let subject = new Subject<string>();
    this.http.post<TokenCiclo>(urlAuthentication, login, options).subscribe(
      (result) => {
        if (result.message) {
          // apresentar mensagem de erro
          //this.alertService.error(result.message);
        } else {
          subject.next(result.token);
        }
      },
      (error) => console.log(error)
    );

    return subject.asObservable();
  }

  validateToken(token: string): Observable<boolean> {
    let validateTokenUrl = `${this.urlApiCiclo}Account/validatoken`
    let httpHead: HttpHeaders = this.getheaders();
    httpHead.set('Authorization', `Bearer ${token}`)
    let options = { headers: httpHead, withCredentials: true };
    let subject = new Subject<boolean>();
    this.http.get<string>(validateTokenUrl, options)
      .subscribe(
        result => subject.next(true),
        (err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              subject.next(false);
            }
          }
        })
      )
    return subject.asObservable();
  }

  private getheaders(): HttpHeaders {
    return new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
  }

  private getUserCiclo(): UserLoginCiclo {
    let login: UserLoginCiclo = new UserLoginCiclo();
    login.userName = environment.userApiCiclo;
    login.password = environment.passwordApiCiclo;
    return login;
  }

}

