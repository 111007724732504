import { Response } from './response.model';

export class UserSession extends Response {
  secretKey:string="";
  token:string="";
  login:string="";
  razaoSocial:string="";
  cnpjEmpresa :string=""; 
  controlAccess:controlAccess;
  logado:boolean = false;
  TokenCiclo:string="";
  ApiAccessKey:string="";
  currentEmpresa:any;
  grupo:string;
  grupo_id:string;
  tipo_empresa:string;
  uuid_empresa:string;
}

export class controlAccess{
  ipOrigin:string;
  origin:string;
  nivelAccess:nivelAccess;
}

export class nivelAccess{
  group:dGroup
}

export class dGroup{
  name:string;
  formsAccess:any[];
}

