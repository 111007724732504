import { Component, Input, forwardRef, Injector, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EntrySelect } from '../../models/EntrySelect.model';
import { GenericInputFieldComponent } from '../generic-input-field/generic-input-field.component';

@Component({
  selector: 'app-select-field-search-tag',
  templateUrl: './select-field-search-tag.component.html',
  styleUrls: ['./select-field-search-tag.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectFieldSearchTagComponent),
    multi: true
  }]
})
export class SelectFieldSearchTagComponent extends GenericInputFieldComponent {
  @Input() bindValue: string = "value";
  @Input() bindLabel: string = "label";
  @Input() itens: Array<any>
  @Input() backColorTag: string = null;
  @Output() change = new EventEmitter();
  constructor(injector: Injector, private cdRef: ChangeDetectorRef) {
    super(injector);
    //this._styleTag()
  }

  onChange(event) {
    //console.log(event);
    this.change.emit(event);
  }

 /*  _styleTag() {
    if (this.backColorTag != null)
      $('.tag-select').css('background-color', this.backColorTag)
  } */

  public ngDoCheck() {
    //resolve: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after ...
    this.cdRef.detectChanges();
  }
}
