import { Component, Input, forwardRef, Injector, ChangeDetectorRef   } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GenericInputFieldComponent } from '../generic-input-field/generic-input-field.component';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputFieldComponent),
    multi: true
  }]
})
export class InputFieldComponent extends GenericInputFieldComponent {
  @Input() maxlength: string
  @Input() type: string = 'text';
  @Input() mask: string = '';   
  @Input() UpperCase: boolean = false;  //força conversão para caixa alta


  constructor(injector: Injector,
              private cdRef: ChangeDetectorRef
    ) {
    super(injector);
   }

   public ngDoCheck() {
    //resolve: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after ...
		this.cdRef.detectChanges();
	}
 
}
