import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'form-field-error',
  templateUrl: './form-field-error.component.html',
  styleUrls: ['./form-field-error.component.css']
})
export class FormFieldErrorComponent implements OnInit {

  @Input('form-control') formControl: FormControl;

  constructor() { }

  ngOnInit(): void {
  }

  public get errorMessage(): string | null {
    if (this.mustShowErrorMessage()) {
      return this.getErrorMessage();
    } else {
      return null;
    }
  }

  private mustShowErrorMessage(): boolean {
    return this.formControl.invalid && this.formControl.touched;
  }

  private getErrorMessage(): string | null{
    if(this.formControl.errors.required) {
      return 'Dado obrigatório.';
    } else if(this.formControl.errors.email) {
    return 'Formato de e-mail inválido.';
    } else if(this.formControl.errors.minlength) {
      let requiredLength = this.formControl.errors.minlength.requiredLength;
      let actualLength = this.formControl.errors.minlength.actualLength;
      return `Tamanho mínimo permitido ${requiredLength}, inforomado ${actualLength}.`;
    } else if(this.formControl.errors.maxlength) {
      let requiredLength = this.formControl.errors.maxlength.requiredLength;
      let actualLength = this.formControl.errors.maxlength.actualLength;
      return `Tamanho máximo permitido ${requiredLength}, informado ${actualLength}.`;
    } else if (this.formControl.errors.min) {
      let requiredLength = this.formControl.errors.min.min;
      return `Valor mínimo ${requiredLength}.`;
    } else if (this.formControl.errors.max) {
      let requiredLength = this.formControl.errors.max.max;
      return `Valor máximo ${requiredLength}.`;
    } else if (this.formControl.errors.metalsoftInteger) {
      return 'Somente números inteiros posítivos são válidos.'
    } else if (this.formControl.errors.metalsoftNumber ) {
      return `Deve ter no máximo ${this.formControl.errors.metalsoftNumber.decimalPlaces} casas decimais.`
    }
    else {
      console.log(this.formControl.errors);
      return 'Valor inválido.'
    }

  }

}
