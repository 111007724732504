import { ChangeDetectorRef, Component, forwardRef, Injector } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import { defineLocale, ptBrLocale } from 'ngx-bootstrap/chronos';
defineLocale('pt-br', ptBrLocale);

import { BsDatepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";

import { GenericInputFieldComponent } from "../generic-input-field/generic-input-field.component";

@Component({
  selector: "app-input-field-date",
  templateUrl: "./input-field-date.component.html",
  styleUrls: ["./input-field-date.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFieldDateComponent),
      multi: true,
    },
  ],
})
export class InputFieldDateComponent extends GenericInputFieldComponent {

  bsConfig: Partial<BsDatepickerConfig>;

  constructor( injector: Injector, private localseService: BsLocaleService,  private cdRef: ChangeDetectorRef) {
    super(injector);
    this.localseService.use('pt-br');
    this.bsConfig = Object.assign(
      {},
      {
        dateInputFormat: "DD/MM/YYYY",
        todayButtonLabel: "Hoje",
        useUtc: false,
        showWeekNumbers: false,
        rangeInputFormat: "DD/MM/YYYY"
      }
    );

  }

  
  public ngDoCheck() {
    //resolve: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after ...
		this.cdRef.detectChanges();
	}

}
