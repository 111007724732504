<label [attr.for]="formControlName" class="form-label {{isRequired()?'required':''}}">{{ label }}</label>
<input
currencyMask 
type="text"
class="form-control"
[id]="formControlName"
[formControl]="control"
[readonly]="isReadOnly"
placeholder="R$ 0,00"
[options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
[tabindex]="isReadOnly?'-1':tabindex"
[autocomplete]="autoComplete"
>
<form-field-error [form-control]="control"></form-field-error>

