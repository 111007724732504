import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  defaultMessage:string= "Carregando...";
  public mensagem = new Subject<string>();
  public isLoading = new Subject<boolean>(); 

  constructor() {
    //default
    this.setText(this.defaultMessage);
   }

  setText(texto:string){
    this.mensagem.next(texto);
  } 

  show(){  
    //console.log('Loading start');
    this.isLoading.next(true);    
     
  }
  hide(){
    //console.log('Loading end');
    this.isLoading.next(false);
    //return default message
    this.setText(this.defaultMessage);
  }
}
