import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NoPageFoundComponent } from './no-page-found/no-page-found.component';
import { AuthGuard } from './guards/auth.guard';
import { AlertFormComponent } from './alert/alert-form/alert-form.component';
import { AlertService } from './alert/shared/alert.service';
import { CstHttpInterceptor } from './interceptors/cst-http.interceptor';
import { SessionService } from '../shared/services/session.service';
import { RouterModule } from '@angular/router';
import { AuthenticationCicloService } from './authentication/authentication-ciclo.service';
import { TokenInterceptor } from './interceptors/token.interceptor'

@NgModule({
  declarations: [NavBarComponent, NoPageFoundComponent, AlertFormComponent],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    RouterModule
  ],
  providers: [
    { provide: 'environmentProvider', useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: CstHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt' },
    AuthGuard,
    SessionService,
    AlertService,
    AuthenticationCicloService
  ],
  exports: [
    BrowserModule,
    HttpClientModule,
    NavBarComponent,
    AlertFormComponent
  ]
})
export class CoreModule { }
