<div class="container" id="contentToConvert">
    <div class="col-12 d-flex">
        <div class="col-6" style="float: left; margin-top: 3px;">
            <span>Comparação de versões dos orçamentos: {{versoesExibir}}</span>
        </div>
        <div class="col-6" id="div-btns" style="float: right;">
            <button class="btn btn-primary" (click)="generatePDF()"><i class="fa fa-print" aria-hidden="true"></i>
                Imprimir</button>
            <button class="btn btn-success" style="font-weight: bold;" (click)="openVersao()"><i class="fa fa-info"
                    aria-hidden="true"></i>
                Versões</button>
        </div>
    </div>
    <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-lg-6 col-md-6 col-sm-6 text-center">
            <img src="{{logo_marca}}" class="logo_marca" width="100px" alt="">
            <span class="text-center font-name-car">{{geracao_car}} - {{modelo}} - {{versao_car}} - {{ano_veiculo}}</span>
        </div>
    </div>
    <div class="row border mt-2">
        <div class="col-md-3 d-flex align-items-center">
            <img src="{{logo}}" class="img-fluid" alt="">
        </div>
        <div class="col-md-9 p-4">
            <div class="row">
                <table class="table table-borderless" style="max-width: 1000px;">
                    <thead class="text-r">
                        <tr>
                            <th class="p-0" style="width: 20%; font-size: 14px" scope="col">Placa</th>
                            <th class="p-0" style="width: 20%; font-size: 14px" scope="col">Chassi</th>
                            <th class="p-0" style="width: 20%; font-size: 14px" scope="col">Combustivel</th>
                            <th class="p-0" style="width: 20%; font-size: 14px" scope="col">Quilometragem</th>
                            <th class="p-0" style="width: 20%; font-size: 14px" scope="col">Cor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="p-0 info-table"
                                style="width: 20%; font-size: 12px; border-bottom: 1px solid #dee2e6;">{{placa}}</td>
                            <td class="p-0 info-table"
                                style="width: 20%; font-size: 12px; border-bottom: 1px solid #dee2e6;">{{chassi}}</td>
                            <td class="p-0 info-table"
                                style="width: 20%; font-size: 12px; border-bottom: 1px solid #dee2e6;">{{combustivel}}
                            </td>
                            <td class="p-0 info-table"
                                style="width: 20%; font-size: 12px; border-bottom: 1px solid #dee2e6;">{{quilometragem}}
                            </td>
                            <td class="p-0 info-table"
                                style="width: 20%; font-size: 12px; border-bottom: 1px solid #dee2e6;">{{cor}}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th class="p-0" style="width: 20%; font-size: 14px;" scope="col">Evento</th>
                            <th class="p-0" style="width: 20%; font-size: 14px" scope="col">Associado</th>
                            <th class="p-0" style="width: 20%; font-size: 14px" scope="col">CPF/CNPJ</th>
                            <th class="p-0" style="width: 50%; font-size: 14px" scope="col">Email</th>
                            <th class="p-0" style="width: 20%; font-size: 14px" scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="p-0 info-table"
                                style="width: 20%; font-size: 12px; border-bottom: 1px solid #dee2e6;">{{evento}}</td>
                            <td class="p-0 info-table"
                                style="width: 20%; font-size: 12px; border-bottom: 1px solid #dee2e6;">{{associado}}
                            </td>
                            <td class="p-0 info-table"
                                style="width: 20%; font-size: 12px; border-bottom: 1px solid #dee2e6;">{{cpf_cnpj}}</td>
                            <td class="p-0 info-table"
                                style="width: 20%; font-size: 12px; border-bottom: 1px solid #dee2e6;">{{email}}</td>
                            <td class="p-0 info-table"
                                style="width: 20%; font-size: 12px; border-bottom: 1px solid #dee2e6;"></td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th class="p-0" style="width: 20%; font-size: 14px" scope="col">Oficina</th>
                            <th class="p-0" style="width: 20%; font-size: 14px" scope="col">Contato</th>
                            <th class="p-0" style="width: 20%; font-size: 14px" scope="col">Responsável</th>
                            <th class="p-0" style="width: 20%; font-size: 14px" scope="col"></th>
                            <th class="p-0" style="width: 20%; font-size: 14px" scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="p-0 info-table"
                                style="width: 20%; font-size: 12px; border-bottom: 1px solid #dee2e6;">{{oficina}}</td>
                            <td class="p-0 info-table"
                                style="width: 20%; font-size: 12px; border-bottom: 1px solid #dee2e6;">{{contato}}</td>
                            <!-- | phone -->
                            <td class="p-0 info-table"
                                style="width: 20%; font-size: 12px; border-bottom: 1px solid #dee2e6;">{{responsavel}}
                            </td>
                            <td class="p-0 info-table"
                                style="width: 20%; font-size: 12px; border-bottom: 1px solid #dee2e6;"></td>
                            <td class="p-0 info-table"
                                style="width: 20%; font-size: 12px; border-bottom: 1px solid #dee2e6;"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mt-4 backgroud-color">
        <span class="text-center text-title">TROCA</span>
    </div>
    <table class="table" style="width: 100%; border-collapse: collapse;">
        <thead>
            <tr style=" box-sizing: border-box; margin: 0px; padding: 0px; ">
                <th scope="col"
                    style="border-bottom: 1px solid #0b5ed7; text-align:inherit; font-size: 10px; width: 30%;">
                    Peça</th>
                <th scope="col" class="col-table">
                    Part Number</th>
                <th scope="col" class="col-table">
                    Origem</th>
                <th scope="col" class="col-table">
                    Fornecimento</th>
                <th scope="col" class="col-table">
                    Tipo de Orçamento</th>
                <th scope="col" class="col-table">
                    P.I.</th>
                <th scope="col" class="col-table">
                    T.M.O.</th>
                <th scope="col" class="col-table">
                    R$ M.O.
                </th>
                <th scope="col" class="col-table">
                    R$ Un. Peça</th>
                <th scope="col" class="col-table">
                    Qtd</th>
                <th scope="col" class="col-table">
                    Desconto</th>
                <th scope="col" class="col-table">
                    R$ T. Peça(s)
                </th>
                <th scope="col" class="col-table">
                    R$ T. Bruto
                </th>
                <th scope="col" class="col-table">
                    R$ T. Liquido
                </th>
                <th scope="col" class="col-table"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of lista_troca" [style]="removido(item.uuid_orcamento)">
                <!-- box-sizing: border-box; margin: 0px; padding: 0px; -->
                <td scope="col" class="td-table">{{item.descricao_peca_ciclo}} {{retornabarra(item.peca_manual)}} <span
                        class="idicador_manual" [title]="title_r(item.peca_manual)"
                        [style]="retorna_display(item.peca_manual)">{{retornaSimNao(item.peca_manual)}}</span></td>
                <td scope="col" class="td-table">{{item.part_number}}</td>
                <td scope="col" class="td-table">{{item.fornecimento_peca}}</td>
                <td scope="col" class="td-table">{{item.fornecedor}}</td>
                <td scope="col" class="td-table">{{item.tipo_orcamento}}</td>
                <td scope="col" class="td-table">{{item.pontos_impacto}}</td>
                <td scope="col" class="td-table">{{item.troca_tempo}} <i
                        title="Tempo de troca original da peça: {{item.tmo_ciclo}}"
                        [class]="retornaClasseIndicadorTMO(item.troca_tempo,item.tmo_ciclo)" aria-hidden="true"></i>
                </td>
                <td scope="col" class="td-table">{{item.troca_valor_servico | currency: 'BRL'}}</td>
                <td scope="col" class="td-table">{{item.valor_item | currency: 'BRL'}} <i
                        title="Valor original da peça: {{item.valor_ciclo}}"
                        [class]="retornaClasseIndicadorPreco(item.valor_item,item.valor_ciclo)" aria-hidden="true"></i>
                </td>
                <td scope="col" class="td-table">{{item.quantidade}}</td>
                <td scope="col" class="td-table">{{item.valor_desconto}}%</td>
                <td scope="col" class="td-table">{{item.valor_subtotal | currency: 'BRL'}}
                </td>
                <td scope="col" class="td-table">{{item.valor_total_bruto | currency: 'BRL'}}
                </td>
                <td scope="col" class="td-table">{{item.valor_liquido | currency: 'BRL'}}
                </td>
                <td scope="col" class="td-table">
                    <i class="fa fa-info" aria-hidden="true" [style]="retornaStyleI(removidoQnd(item.uuid_orcamento))"
                        [title]="(removidoQnd(item.uuid_orcamento) == null)? '': ('Item removido na versao: ' + removidoQnd(item.uuid_orcamento))"></i>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="row mt-4 backgroud-color">
        <span class="text-center text-title">REMOÇÃO E INSTALAÇÃO</span>
    </div>
    <table class="table" style="width: 100%; border-collapse: collapse;">
        <thead>
            <tr style=" box-sizing: border-box; margin: 0px; padding: 0px; ">
                <th scope="col"
                    style="border-bottom: 1px solid #0b5ed7; text-align:inherit; font-size: 10px; width: 30%;">
                    Peça</th>
                <th scope="col" class="col-table">
                    P.I.</th>
                <th scope="col" class="col-table">
                    T.M.O.</th>
                <th scope="col" class="col-table">
                    M.Obra</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of lista_remocao_instalacao"
                style="box-sizing: border-box; margin: 0px; padding: 0px;">
                <td scope="col" class="td-table">{{item.descricao_peca_ciclo}}</td>
                <td scope="col" class="td-table">{{item.pontos_impacto}}
                </td>
                <td scope="col" class="td-table">{{item.remocao_instalacao_tempo}}</td>
                <td scope="col" class="td-table">{{item.remocao_inst_vl_servico| currency: 'BRL'}}</td>
            </tr>
        </tbody>
    </table>
    <div class="row mt-4 backgroud-color">
        <span class="text-center text-title">PINTURA</span>
    </div>
    <table class="table" style="width: 100%; border-collapse: collapse;">
        <thead>
            <tr style=" box-sizing: border-box; margin: 0px; padding: 0px; ">
                <th scope="col"
                    style="border-bottom: 1px solid #0b5ed7; text-align:inherit; font-size: 10px; width: 30%;">
                    Peça</th>
                <th scope="col" class="col-table">
                    P.I.</th>
                <th scope="col" class="col-table">
                    T.M.O.</th>
                <th scope="col" class="col-table">
                    M.Obra</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of lista_pintura" style="box-sizing: border-box; margin: 0px; padding: 0px;">
                <td scope="col" class="td-table">{{item.descricao_peca_ciclo}}</td>
                <td scope="col" class="td-table">{{item.pontos_impacto}}
                </td>
                <td scope="col" class="td-table">{{item.pintura_tempo}}</td>
                <td scope="col" class="td-table">{{item.pintura_valor_servico | currency: 'BRL'}}</td>
            </tr>
        </tbody>
    </table>
    <div class="row mt-4 backgroud-color">
        <span class="text-center text-title">REPARAÇÃO</span>
    </div>
    <table class="table" style="width: 100%; border-collapse: collapse;">
        <thead>
            <tr style=" box-sizing: border-box; margin: 0px; padding: 0px; ">
                <th scope="col"
                    style="border-bottom: 1px solid #0b5ed7; text-align:inherit; font-size: 10px; width: 30%;">
                    Peça</th>
                <th scope="col" class="col-table">
                    P.I.</th>
                <th scope="col" class="col-table">
                    T.M.O.</th>
                <th scope="col" class="col-table">
                    M.Obra</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of lista_reparacao" style="box-sizing: border-box; margin: 0px; padding: 0px;">
                <td scope="col" class="td-table">{{item.descricao_peca_ciclo}}</td>
                <td scope="col" class="td-table">{{item.pontos_impacto}}
                </td>
                <td scope="col" class="td-table">{{item.reparo_tempo}}</td>
                <td scope="col" class="td-table">{{item.reparo_valor_servico | currency: 'BRL'}}</td>
            </tr>
        </tbody>
    </table>
    <div class="row mt-4 backgroud-color">
        <span class="text-center text-title">SERVIÇOS ADICIONAIS</span>
    </div>
    <table class="table" style="width: 100%; border-collapse: collapse;">
        <thead>
            <tr style=" box-sizing: border-box; margin: 0px; padding: 0px; ">
                <th scope="col"
                    style="border-bottom: 1px solid #0b5ed7; text-align:inherit; font-size: 10px; width: 30%;">
                    Serviço</th>
                <th scope="col" class="col-table">
                    Fornecimento</th>
                <th scope="col" class="col-table">
                    P.I.</th>
                <th scope="col" class="col-table">
                    M.Obra</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of lista_servico" style="box-sizing: border-box; margin: 0px; padding: 0px;">
                <td scope="col" class="td-table">{{item.descricao_peca_ciclo}}</td>
                <td scope="col" class="td-table">{{item.fornecedor}}</td>
                <td scope="col" class="td-table">{{item.pontos_impacto}}
                </td>
                <td scope="col" class="td-table">{{item.valor_total_bruto | currency: 'BRL'}}</td>
            </tr>
        </tbody>
    </table>
    <div class="row">
        <div class="col-md-5">
            <div class="row">
                <span class="text-center backgroud-color text-title">MÃO DE OBRA</span>
            </div>
            <table class="table" style="width: 100%; border-collapse: collapse;">
                <thead>
                    <tr style=" box-sizing: border-box; margin: 0px; padding: 0px; ">
                        <th scope="col" class="descricao-td">
                            Descrição</th>
                        <th scope="col" class="col-table">
                            Valor Hora</th>
                        <th scope="col" class="col-table">
                            Horas</th>
                        <th scope="col" class="col-table">
                            Valor Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <td scope="col" class="td-mao-obra">Funilaria</td>
                        <td scope="col" class="td-mao-obra">{{mao_valor_funilaria | currency :'BRL'}}</td>
                        <td scope="col" class="td-mao-obra">{{mao_hora_funilaria | number : '1.2-2'}}</td>
                        <td scope="col" class="td-mao-obra">{{mao_total_funilaria | currency :'BRL'}}</td>
                    </tr>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <td scope="col" class="td-mao-obra">Pintura</td>
                        <td scope="col" class="td-mao-obra">{{mao_valor_pintura | currency :'BRL'}}</td>
                        <td scope="col" class="td-mao-obra">{{mao_hora_pintura | number : '1.2-2'}}</td>
                        <td scope="col" class="td-mao-obra">{{mao_total_pintura | currency :'BRL'}}</td>
                    </tr>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <td scope="col" class="td-mao-obra">Elétrica</td>
                        <td scope="col" class="td-mao-obra">{{mao_valor_eletrica | currency :'BRL'}}</td>
                        <td scope="col" class="td-mao-obra">{{mao_hora_eletrica | number : '1.2-2'}}</td>
                        <td scope="col" class="td-mao-obra">{{mao_total_eletrica | currency :'BRL'}}</td>
                    </tr>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <td scope="col" class="td-mao-obra">Tapeçaria</td>
                        <td scope="col" class="td-mao-obra">{{mao_valor_tapecaria | currency :'BRL'}}</td>
                        <td scope="col" class="td-mao-obra">{{mao_hora_tapecaria | number : '1.2-2'}}</td>
                        <td scope="col" class="td-mao-obra">{{mao_total_tapecaria | currency :'BRL'}}</td>
                    </tr>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <td scope="col" class="td-mao-obra">Mecânica</td>
                        <td scope="col" class="td-mao-obra">{{mao_valor_mecanica | currency :'BRL'}}</td>
                        <td scope="col" class="td-mao-obra">{{mao_hora_mecanica | number : '1.2-2'}}</td>
                        <td scope="col" class="td-mao-obra">{{mao_total_mecanica | currency :'BRL'}}</td>
                    </tr>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <td scope="col" class="td-mao-obra">Vidraçaria</td>
                        <td scope="col" class="td-mao-obra">{{mao_valor_vidracaria | currency :'BRL'}}</td>
                        <td scope="col" class="td-mao-obra">{{mao_hora_vidracaria | number : '1.2-2'}}</td>
                        <td scope="col" class="td-mao-obra">{{mao_total_vidracaria | currency :'BRL'}}</td>
                    </tr>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <td scope="col" class="td-mao-obra">Recuperação</td>
                        <td scope="col" class="td-mao-obra">{{mao_valor_recuperacao | currency:'BRL'}}</td>
                        <td scope="col" class="td-mao-obra">{{mao_hora_recuperacao | number : '1.2-2'}}</td>
                        <td scope="col" class="td-mao-obra">{{mao_total_recuperacao | currency :'BRL'}}</td>
                    </tr>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <td scope="col" class="td-mao-obra">Remoção e instalação</td>
                        <td scope="col" class="td-mao-obra">{{mao_valor_dados_remocao_inst | currency:'BRL'}}</td>
                        <td scope="col" class="td-mao-obra">{{mao_hora_dados_remocao_inst | number : '1.2-2'}}</td>
                        <td scope="col" class="td-mao-obra">{{mao_total_remocao_inst | currency :'BRL'}}</td>
                    </tr>
                    <!--  <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <td scope="col" class="td-mao-obra">Terceiros</td>
                        <td scope="col" class="td-mao-obra">{{0| currency:'BRL'}}</td>
                        <td scope="col" class="td-mao-obra">{{0 | number : '1.2-2'}}</td>
                        <td scope="col" class="td-mao-obra">{{0 | currency :'BRL'}}</td>
                    </tr> -->
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <th scope="col" class="td-mao-obra">MÃO DE OBRA</th>
                        <td scope="col" class="td-mao-obra"></td>
                        <th scope="col" class="td-mao-obra">{{mao_totalizador_hora | number : '1.2-2'}}</th>
                        <th scope="col" class="td-mao-obra">{{mao_totalizador_valor | currency :'BRL'}}</th>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-7">
            <div class="row backgrou-resumo">
                <span class="text-center text-white">RESUMO - VALORES</span>
            </div>
            <table class="table" style="width: 100%; border-collapse: collapse;">
                <thead>
                    <tr>
                        <th scope="col" class="descricao-td">
                            Descrição</th>
                        <th scope="col" class="col-table">
                            Valor</th>
                        <th scope="col" class="col-table" style="width: 30%;">
                            Descrição</th>
                        <th scope="col" class="col-table text-end">
                            Valor</th>
                        <th scope="col" class="col-table">
                    </tr>
                </thead>
                <tbody>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <th scope="col" class="td-table">R$ TOTAL M.O.</th>
                        <th scope="col" class="td-table">{{mao_totalizador_valor | currency :'BRL'}}</th>
                        <td scope="col" class="td-table">AVARIAS PRÉVIAS</td>
                        <td scope="col" class="td-table">{{avaria| currency :'BRL'}}</td>
                    </tr>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <th scope="col" class="td-table">R$ TOTAL PEÇAS</th>
                        <th scope="col" class="td-table">{{total_pecas | currency : 'BRL'}}</th>
                        <td scope="col" class="td-table">DEDUÇÃO DNC</td>
                        <td scope="col" class="td-table">{{deducao_dnc | currency: 'BRL'}}</td>
                    </tr>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <td scope="col" class="td-table">Peças (G)</td>
                        <td scope="col" class="td-table">{{peca_genuina | currency :'BRL'}}</td>
                        <td scope="col" class="td-table">Divergência</td>
                        <td scope="col" class="td-table">{{valor_divergencia| currency :'BRL'}}</td>
                    </tr>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <td scope="col" class="td-table">Peças (P)</td>
                        <td scope="col" class="td-table">{{peca_paralela | currency :'BRL'}}</td>
                        <td scope="col" class="td-table"></td>
                        <td scope="col" class="td-table"></td>
                    </tr>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <td scope="col" class="td-table">Peças (D)</td>
                        <td scope="col" class="td-table">{{peca_desmanche | currency :'BRL'}}</td>
                        <td scope="col" class="td-table"></td>
                        <td scope="col" class="td-table"></td>
                    </tr>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <th scope="col" class="td-table">R$ TOTAL Adicional</th>
                        <th scope="col" class="td-table">{{total_adicional | currency : 'BRL'}}</th>
                        <td scope="col" class="td-table"></td>
                        <td scope="col" class="td-table"></td>
                    </tr>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <th scope="col" class="td-table">R$ TOTAL DO ORÇAMENTO (BRUTO)</th>
                        <th scope="col" class="td-table">{{total_orcamento_b| currency :'BRL'}}</th>
                        <th scope="col" class="td-table"></th>
                        <td scope="col" class="td-table"></td>
                    </tr>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <th scope="col" class="td-table">R$ TOTAL Deduções</th>
                        <th scope="col" class="td-table">{{valor_deducoes | currency: 'BRL'}}</th>
                        <td scope="col" class="td-table"></td>
                        <td scope="col" class="td-table"></td>
                    </tr>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <td scope="col" class="td-table">Desconto</td>
                        <td scope="col" class="td-table">{{pecas_descontos | currency : 'BRL'}}</td>
                        <td scope="col" class="td-table"></td>
                        <td scope="col" class="td-table"></td>
                    </tr>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <td scope="col" class="td-table">Franquia</td>
                        <td scope="col" class="td-table">{{franquia | currency: 'BRL'}}</td>
                        <td scope="col" class="td-table"></td>
                        <td scope="col" class="td-table"></td>
                    </tr>
                    <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                        <th scope="col" class="td-table">R$ Total do Orçamento (Líquido)</th>
                        <th scope="col" class="td-table">{{total_pecas_l | currency: 'BRL'}}</th>
                        <th scope="col" class="td-table"></th>
                        <td scope="col" class="td-table"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row backgrou-resumo">
        <span class="text-center text-white ">RESUMO DOS PONTOS DE IMPACTO</span>
    </div>
    <table class="table" style="width: 100%; border-collapse: collapse;">
        <thead>
            <tr style=" box-sizing: border-box; margin: 0px; padding: 0px; ">
                <th scope="col"
                    style="border-bottom: 1px solid #0b5ed7; text-align:inherit; font-size: 10px; width: 30%;">
                    Descrição</th>
                <th scope="col" class="col-table">
                    1º Ponto</th>
                <th scope="col" class="col-table">
                    2º Ponto</th>
                <th scope="col" class="col-table">
                    3º Ponto</th>
                <th scope="col" class="col-table">
                    4º Ponto</th>
                <th scope="col" class="col-table">
                    5º Ponto</th>
                <th scope="col" class="col-table">
                    6º Ponto
                </th>
                <th scope="col" class="col-table">
                    7º Ponto</th>
                <th scope="col" class="col-table">
                    8º Ponto</th>
            </tr>
        </thead>
        <tbody>
            <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                <th scope="col" class="td-table">Mão de Obra</th>
                <td scope="col" class="td-table">{{pi1_mao_obra |currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi2_mao_obra |currency:'BRL'}}</td>
                <td scope="col" class="td-table">{{pi3_mao_obra |currency:'BRL'}}</td>
                <td scope="col" class="td-table">{{pi4_mao_obra |currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi5_mao_obra |currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi6_mao_obra |currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi7_mao_obra |currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi8_mao_obra |currency:'BRL'}}
                </td>
            </tr>
            <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                <th scope="col" class="td-table">Peças</th>
                <td scope="col" class="td-table">{{pi1_pecas |currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi2_pecas |currency:'BRL'}}</td>
                <td scope="col" class="td-table">{{pi3_pecas |currency:'BRL'}}</td>
                <td scope="col" class="td-table">{{pi4_pecas |currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi5_pecas |currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi6_pecas |currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi7_pecas |currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi8_pecas |currency:'BRL'}}
                </td>
            </tr>
            <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                <th scope="col" class="td-table">Franquia</th>
                <td scope="col" class="td-table">{{franquia |currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">R$ 0,00</td>
                <td scope="col" class="td-table">R$ 0,00</td>
                <td scope="col" class="td-table">R$ 0,00
                </td>
                <td scope="col" class="td-table">R$ 0,00
                </td>
                <td scope="col" class="td-table">R$ 0,00
                </td>
                <td scope="col" class="td-table">R$ 0,00
                </td>
                <td scope="col" class="td-table">R$ 0,00
                </td>
            </tr>
            <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                <th scope="col" class="td-table">Avarias</th>
                <td scope="col" class="td-table">{{pi1_avaria | currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi2_avaria | currency:'BRL'}}</td>
                <td scope="col" class="td-table">{{pi3_avaria | currency:'BRL'}}</td>
                <td scope="col" class="td-table">{{pi4_avaria | currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi5_avaria | currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi6_avaria | currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi7_avaria | currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi8_avaria | currency:'BRL'}}
                </td>
            </tr>
            <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                <th scope="col" class="td-table">Total Serviços adicionais</th>
                <td scope="col" class="td-table">{{pi1_adicional | currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi2_adicional | currency:'BRL'}}</td>
                <td scope="col" class="td-table">{{pi3_adicional | currency:'BRL'}}</td>
                <td scope="col" class="td-table">{{pi4_adicional | currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi5_adicional | currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi6_adicional | currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi7_adicional | currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi8_adicional | currency:'BRL'}}
                </td>

            </tr>
            <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                <th scope="col" class="td-table">Desconto</th>
                <td scope="col" class="td-table">{{pi1_desconto | currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi2_desconto | currency:'BRL'}}</td>
                <td scope="col" class="td-table">{{pi3_desconto | currency:'BRL'}}</td>
                <td scope="col" class="td-table">{{pi4_desconto | currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi5_desconto | currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi6_desconto | currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi7_desconto | currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi8_desconto | currency:'BRL'}}
                </td>
            </tr>
            <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                <th scope="col" class="td-table">Total (Bruto)</th>
                <td scope="col" class="td-table">{{pi1_total_bruto | currency: 'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi2_total_bruto | currency: 'BRL'}}</td>
                <td scope="col" class="td-table">{{pi3_total_bruto | currency: 'BRL'}}</td>
                <td scope="col" class="td-table">{{pi4_total_bruto | currency: 'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi5_total_bruto | currency: 'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi6_total_bruto | currency: 'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi7_total_bruto | currency: 'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi8_total_bruto | currency: 'BRL'}}
                </td>
            </tr>
            <tr style="box-sizing: border-box; margin: 0px; padding: 0px;">
                <th scope="col" class="td-table">Total Liquido</th>
                <td scope="col" class="td-table">{{pi1_total_liquido |currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi2_total_liquido |currency:'BRL'}}</td>
                <td scope="col" class="td-table">{{pi3_total_liquido |currency:'BRL'}}</td>
                <td scope="col" class="td-table">{{pi4_total_liquido |currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi5_total_liquido |currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi6_total_liquido |currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi7_total_liquido |currency:'BRL'}}
                </td>
                <td scope="col" class="td-table">{{pi8_total_liquido |currency:'BRL'}}
                </td>
            </tr>
        </tbody>
    </table>

    <div class="row mb-4 backgroud-color">
        <span class="text-center text-title">NOTAS DO EVENTO</span>
    </div>
    <div class="row mb-4 backgroud-color">
        <span class="text-center text-title">AVISOS</span>
    </div>
    <div class="row">
        <div class="col-md-12">
            <span class="mb-2 font-1">Observação: {{observacao}}</span>
        </div>
        <div class="col-md-12 border-botom">
            <span class="mb-2 font-1">Motivo:</span>
        </div>
    </div>
</div>