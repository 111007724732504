import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { environment } from '../../../environments/environment';

import { SessionService } from '../../shared/services/session.service';
import { AuthenticationCicloService } from '../authentication/authentication-ciclo.service'

const TOKEN_HEADER_KEY = 'Authorization'

//referencia: https://www.bezkoder.com/angular-12-refresh-token/

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  env: any;
  constructor(
    private authenticationCicloService: AuthenticationCicloService,
    private sessionService: SessionService) {
      this.env = environment;
     }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    let url = req.url;
    if(url.includes(environment.apiCicloUrl)){            
          const userSession = this.sessionService.getCurrentSession();          
          if (!this.urlOrigemCiclo(req.url)) {
            authReq = this.removeTokenHeader(req, userSession.TokenCiclo);
          } else if (userSession?.token != null) {
            authReq = this.addTokenHeader(req, userSession.TokenCiclo);
          }

          return next.handle(authReq).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && !authReq.url.includes('login') && error.status === 401) {
              return this.handle401Error(authReq, next);
            }
            return throwError(error);
          }));
 
    } else {
      return next.handle(req);
    }  
  }

  private urlOrigemCiclo(url: string): boolean {
    return url.includes(this.env.apiCicloUrl);
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, `Bearer ${token}`) });
  }

  private removeTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.delete(TOKEN_HEADER_KEY, `Bearer ${token}`) });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return this.authenticationCicloService.getToken().pipe(
      switchMap((token: any) => {
        this.sessionService.setCurrentTokenCiclo(token);
        return next.handle(this.addTokenHeader(request, token));
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

}
