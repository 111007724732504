import { ComunicacaoService } from './shared/services/comunicacao.service';
import { UserSession } from './shared/models/user-session.model';
import { environment } from './../environments/environment';
import { SessionService } from './shared/services/session.service';
import { Component, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { EntrySelect } from './shared/models/EntrySelect.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RestService } from './shared/services/rest.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public logado: Subject<boolean> = this.session.isLogado;
  listEmpresa: Subject<Array<EntrySelect>> = this.rest.empresas;
  resourceForm: FormGroup;


  width: string = "10";
  title = 'orcamentacao-client';
  menu_visible = true;
  //session
  sessao: UserSession;

  constructor(private session: SessionService,
    private router: Router,
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef,
    private rest: RestService,
    private comu: ComunicacaoService
  ) {
    //this.session.getUserSession();
    this.width = this.logado ? "10" : "12";

    this.rest.loadListEmpresas();

    //validators  
    this.resourceForm = this.formBuilder.group({
      currentEmpresa: [JSON.stringify(this.session.getCurrentEmpresa()), []]
    });

    this.sessao = this.session.getUserSession();

    //atualiza current empresa no combo ao modificar o serviço subject.next()
    this.session.getSessionSubject().subscribe(
      data => {
        this.sessao = data;
        //var response = JSON.parse(JSON.stringify(data));        
        if (this.resourceForm.get('currentEmpresa')) {
          let currentEmpresaSession = JSON.stringify(data.currentEmpresa);
          if (this.resourceForm.get('currentEmpresa')?.value != currentEmpresaSession) {
            this.resourceForm.get('currentEmpresa')?.setValue(currentEmpresaSession);
          }
        }
      });
    this.comu.listen().subscribe((m: any) => {
      if (m == 'hide-bar') {
        $('#nav-b').addClass('hide');
      }
      else if (m == 'show-bar') {
        $('#nav-b').removeClass('hide');
      }
    })
  }

  toggleMenu() {
    this.menu_visible = !this.menu_visible;
  }

  logoff() {
    this.session.clear();
    this.router.navigate(['/login/login-form']);
  }


  onChangeEmpresa(event) {
    let current = this.resourceForm.get('currentEmpresa')?.value;
    if (current) {
      this.session.setCurrentEmpresa(JSON.parse(current));
    }
    //this.router.navigate(['/home']);   
    window.location.reload();
  }

  allowChangeEmpresa() {
    return this.session.checkInGrupo([2, 3, 4]) && this.session.isMaster();
  }

  public ngDoCheck() {
    //resolve: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after ...
    this.ref.detectChanges();
  }



}