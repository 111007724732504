<!-- 
<div class="overlay"></div>
    <div class="d-flex justify-content-center position-absolute bottom-50 end-50">  
      <div class="spinner-grow text-primary" role="status" style="width: 3rem; height: 3rem; z-index: 20;">
      </div>
    </div>
-->
<div id="overlay" *ngIf="isLoading | async" >
  <div class="spinner"></div>
  <br/>
  {{mensagem }}
</div>
