import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { sha256 } from 'js-sha256';
import { Observable, Subject, throwError } from 'rxjs';
import { SessionService } from './session.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { EntrySelect } from '../models/EntrySelect.model';
import { Versao } from 'src/app/pages/veiculo/shared/model/versao.model';


@Injectable({
  providedIn: 'root'
})
export class RestService {
  protected ws;
  protected login: string = "";
  public empresas = new Subject<Array<EntrySelect>>();

  constructor(protected http: HttpClient,
    protected session: SessionService,
    protected router: Router
  ) {
    //get rest URL
    this.ws = environment.apiSigaParts;
  };

  getOficinasOrcamento() {
    let postData = {
      "type": "CORPORATE",
      "typeData": "all",
      "limit": "1000",
      "offset": "1"
    }
    return this.http.post(this.ws + "wsOrcamentosOficina.rule?sys=API", JSON.stringify(postData));
  }

  getEmpresasCadastroUsuario(tipo:string = 'CORPORATE'){
    let postdata = {
      "method":"GET",
      "flag":"cadastro_usuario",
      "tipo_empresa":tipo
    }
    return this.http.post(this.ws + 'wsEmpresa.rule?sys=API', JSON.stringify(postdata));
  }

  criarVersao(protocolo, versao) {
    let postData = {
      "method": "get",
      "type": "protocolo",
      "protocol": protocolo,
      "versao": versao
    }
    return this.http.post(this.ws + "wsVersionamento.rule?sys=API", JSON.stringify(postData));
  }

  getVersionamento(protocolo) {
    let postData = {
      "method": "get",
      "type": "protocolo",
      "protocol": protocolo
    }
    return this.http.post(this.ws + "wsSolicitaVersao.rule?sys=API", JSON.stringify(postData));
  }

  getVersoes(protocolo) {
    let postData = {
      "method": "get",
      "type": "versoes",
      "protocol": protocolo
    }
    return this.http.post(this.ws + "wsSolicitaVersao.rule?sys=API", JSON.stringify(postData));
  }

  getVersionamentoByVersao(protocolo, versao) {
    let postData = {
      "method": "get",
      "type": "versao",
      "protocol": protocolo,
      "versao": versao
    }
    return this.http.post(this.ws + "wsSolicitaVersao.rule?sys=API", JSON.stringify(postData));
  }

  getPecasByVersao(protocolo, versao) {
    let postData = {
      "method": "get",
      "type": "mult_itens",
      "protocol": protocolo,
      "versao": versao
    }
    return this.http.post(this.ws + "wsSolicitaVersao.rule?sys=API", JSON.stringify(postData));
  }

  getVersoesOrcamento(protocolo) {
    let postData = {
      "method": "get",
      "type": "versoes",
      "protocol": protocolo
    }
    return this.http.post(this.ws + "wsSolicitaVersao.rule?sys=API", JSON.stringify(postData));
  }

  getOficinaOrcamentoUUID(uuid) {
    let postData = {
      "type": "CORPORATE",
      "typeData": "all",
      "limit": "1000",
      "offset": "1",
      "uuid": uuid
    }
    return this.http.post(this.ws + "wsOrcamentosOficina.rule?sys=API", JSON.stringify(postData));
  }

  getEmpresaUUID(uuid) {
    let postData = {
      "method": "GET",
      "uuid": uuid
    }
    return this.http.post(this.ws + "wsAtribuicao.rule?sys=API", JSON.stringify(postData));
  }

  salvarAtribuicao(postData) {
    return this.http.put(this.ws + "wsAtribuicao.rule?sys=API", JSON.stringify(postData));
  }

  getReguladorasOrcamento() {
    let postData = {
      "method": "GET",
      "type": "REGULADORA",
      "typeData": "all",
      "dataName": "",
      "taxId": "",
      "limit": "1000",
      "offset": "1",
      "place": "getReguladora"
    }
    return this.http.post(this.ws + "wsEmpresaStatus.rule?sys=API", JSON.stringify(postData));
  }

  soNumero(value) {
    return value == null ? null : value.replace(/[^\d]+/g, '');
  }

  getHash(texto: string, secretKey: any = null) {
    if (!secretKey) {
      secretKey = this.session.getSecretKey();
    }
    return sha256.hmac(secretKey, texto);
  }

  getControlAccess(login: string, senha: string) {
    return {
      "chave": this.getHash(login + senha),
      "origem": "Web"
    };

  }

  getSecretKey(login: string) {
    let observable = new Observable(observer => {
      let postData = {
        'login': login
      };
      let data = this.http.post(this.ws + "wsIdentificadorEmpresa.rule?sys=API", JSON.stringify(postData))
        .subscribe(
          data => {
            let response = JSON.parse(JSON.stringify(data));
            //check error
            if (response.status == 'error') {
              observer.error(response.data);
            }
            //set dados identificador da empresa
            let Secret_Key = response.data[0].secretKey;
            this.session.setSecretKey(Secret_Key);
            observer.next(Secret_Key);
          },
          error => {
            observer.error(error);
          }
        );

    });
    return observable;
  }

  autenticate(login: string, senha: string): any {
    this.login = login;
    let postData = {
      'login': login,
      "controlAccess": this.getControlAccess(login, senha)
    };
    return this.http.post(this.ws + "wsLogin.rule?sys=API", JSON.stringify(postData))

  }

  validaToken(token: string) {
    let postData = {
      'login': this.login,
      "controlAccess": {
        "token": token,
        "origem": "Web"
      }
    };
    return this.http.post(this.ws + "wsValidarToken.rule?sys=API", JSON.stringify(postData));
  }

  //request token "esqueci a senha?"
  SolicitarTokenSenha(login: string): any {
    this.login = login;
    let postData = {
      'login': login
    };
    return this.http.post(this.ws + "wsSolicitarTokenSenha.rule?sys=API", JSON.stringify(postData))

  }

  //request token "esqueci a senha?"
  ConfirmarNovaSenha(token: string, senha: string): any {
    let login = this.login;
    let postData = {
      "login": login,
      "controlAccess": {
        "chave": this.getHash(login + senha),
        "token": token,
        "origem": "Web"
      }
    }
    return this.http.post(this.ws + "wsConfirmarNovaSenha.rule?sys=API", JSON.stringify(postData))

  }

  getStates(): any {
    let postData = {}
    return this.http.post(this.ws + "wsBuscarUF.rule?sys=API", JSON.stringify(postData));
  }

  getCitys(idUF): any {
    let postData = { "idUF": idUF }
    return this.http.post(this.ws + "wsBuscarCidades.rule?sys=API", JSON.stringify(postData));
  }


  //cadastro orçamento
  createBudget(post) {
    return this.http.post(this.ws + "wsOrcamentos.rule?sys=API", JSON.stringify(post))
  }
  editBudget(post) {
    return this.http.put(this.ws + "wsOrcamentos.rule?sys=API", JSON.stringify(post))
  }

  //Buscar todos Orçamentos
  getBudget(offset: number = 1, limit: number = 10,) {
    let postData = {
      "method": "GET",
      "limit": limit.toString(),
      "offset": offset.toString(),
      "type": "date",
      "betweenDate": {
        "start": "2022-03-1",
        "end": "2022-03-30"
      }
    }
    return this.http.post(this.ws + "wsOrcamentos.rule?sys=API", JSON.stringify(postData))
  }

  //cadastro empresa
  wsEmpresa(postData, update = false): any {
    if (update) {
      return this.http.put(this.ws + "wsEmpresa.rule?sys=API", JSON.stringify(postData));
    } else {
      return this.http.post(this.ws + "wsEmpresa.rule?sys=API", JSON.stringify(postData));
    }
  }

  //get dados da empresa
  getEmpresa(uuid): any {
    let postData = {
      'method': 'GET',
      'client': {
        'taxIdentifier': {
          'uuid': uuid
        }
      }
    }
    return this.http.post(this.ws + "wsEmpresa.rule?sys=API", postData)
  }


  /* All empresas
  type = CORPORATE, OFICINAL, FILIAL, ETC
  typeData = all, name, taxId, companiNamy
  dataValue = valor procurado
  */
  getEmpresasFilter(type, typeData = "all", dataValue = "", page = 1, limit = 10, noGlobal = false): any {
    let dataFilterName = "dataName";

    let postData = {
      "method": "GET",
      "type": type,
      "typeData": typeData,
      "dataName": typeData == "name" ? dataValue : "",
      "taxId": typeData == "taxId" ? dataValue : "",
      "limit": limit,
      "offset": page, //((page-1)*limit)+1,
    }

    return this.http.post(this.ws + "wsEmpresaStatus.rule?sys=API" + (noGlobal ? "&noGlobal" : ""), JSON.stringify(postData))
  }

  //All empresas
  getEmpresas(typeData = "all", dataValue = "", page = 1, limit = 10): any {
    return this.getEmpresasFilter("CORPORATE", typeData, dataValue, page, limit);
  }

  //all filiais
  getFiliais(typeData = "all", dataValue = "", page = 1, limit = 10): any {
    return this.getEmpresasFilter("FILIAL", typeData, dataValue, page, limit, true);
  }

  //all oficinas
  getOficinas(typeData = "all", dataValue = "", page = 1, limit = 10): any {
    return this.getEmpresasFilter("OFICINA", typeData, dataValue, page, limit, true);
  }

  //all Reguladoras
  getReguladoras(typeData = "all", dataValue = "", page = 1, limit = 10): any {
    return this.getEmpresasFilter("REGULADORA", typeData, dataValue, page, limit, true);
  }



  //update status EMPRESA
  setStatusEmpresa(uuid, newStatus, note = null): any {
    //newStatus: //ATIVO|INATIVO|BLOQUEADO
    let postData = {
      "client": {
        "taxIdentifier": {
          "uuid": uuid
        },
        "status": {
          "situation": newStatus,
          "note": note
        }
      }
    }
    return this.http.post(this.ws + "wsEmpresaAtualizarStatus.rule?sys=API", JSON.stringify(postData))
  }

  //Store User
  storeUser(postData, update: boolean = false): any {
    if (update) {
      return this.http.put(this.ws + "wsUsuarios.rule?sys=API", JSON.stringify(postData));
    } else {
      return this.http.post(this.ws + "wsUsuarios.rule?sys=API", JSON.stringify(postData));
    }
  }

  //All users
  getUsers(): any {
    let postData = { "method": "GET" }
    return this.http.post(this.ws + "wsUsuarios.rule?sys=API", JSON.stringify(postData))
  }

  getAllUsers(): any {
    let postData = { "method": "GET", "type": "all" }
    return this.http.post(this.ws + "wsUsuarios.rule?sys=API", JSON.stringify(postData))
  }

  getUsersPaged(page: number, limit: number): any {
    let postData = {
      "method": "GET",
      "type": "all",
      "limit": limit,
      "offset": page, //((page-1)*limit)+1, 
    }
    return this.http.post(this.ws + "wsUsuarios.rule?sys=API", JSON.stringify(postData))
  }

  /*  getUsersByTipoEmpresa(tipos: any) {
     let postData = { "method": "GET", "tipo_emp": tipos }
     return this.http.post(this.ws + "wsAtribuicao.rule?sys=API", JSON.stringify(postData))
   } */

  getUserByIDempresa(id) {
    let postData = {}
  }

  //All user atribuições
  getUsersAtribuicoes(): any {
    let postData = { "method": "GET" }
    return this.http.post(this.ws + "wsAtribuicao.rule?sys=API", JSON.stringify(postData))
  }

  //user
  getUser(taxId): any {
    let postData = {
      'method': 'GET',
      'client': {
        'taxIdentifier': {
          'taxId': taxId
        }
      }
    }
    return this.http.post(this.ws + "wsUsuarios.rule?sys=API", JSON.stringify(postData))
  }

  //update status User
  setStatusUser(taxId, newStatus, note = null): any {
    //newStatus: //ATIVO|INATIVO|BLOQUEADO
    let postData = {
      "client": {
        "taxIdentifier": {
          "taxId": taxId,
          "name": "",
          "email": ""
        },
        "details": {
          "password": "",
          "phoneNumber": "",
          "group": "",
          "status": newStatus
        }
      }
    }

    return this.http.put(this.ws + "wsUsuarios.rule?sys=API", JSON.stringify(postData));
  }


  //este método carrega a lista de empresas para escolha do perfil current
  loadEmpresas() {
    return this.getEmpresas();
  }

  getCep(cep) {
    return this.http.post(this.ws + 'wsBuscaCep.rule?sys=API', cep)
  }

  //Utilizada para carregar lista de empresas (topo app.components)
  loadListEmpresas() {
    if (this.session.isAuthenticated() == false) {
      this.empresas.next([]);
      return;
    }
    this.loadEmpresas().subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        let itens = response.data;
        let dados: Array<EntrySelect> = [];
        itens.forEach(item => {
          let key = { "cnpj": item.cnpj, 'secretKey': item.secret_key, 'razaoSocial': item.razao_social };
          dados.push({ 'value': JSON.stringify(key), 'label': item.razao_social });
        });
        this.empresas.next(dados);
      });

  }



}
