import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(private router: Router) { }  

  showConfirme(texto):Promise<any>{
    return Swal.fire({
      showCancelButton: false,
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
      cancelButtonText: 'Cancelar',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-success mx-2',
        denyButton: 'btn btn-danger mx-2'
      },
      icon: 'question',
      html: texto,
      backdrop: true,
      focusConfirm: true
    });
  }

    showSuccess(texto, url=""){
      return Swal.fire({
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        icon: 'success',
        //title: 'Muito bem',
        html: texto,
        //showConfirmButton: false,
        //timer: 30000,
        backdrop: true,
        focusConfirm: true
      }).then(function() {
        if(url && url.length>0){  
          //this.router.navigate([url]);
        }
      });
    }

    showAlert(texto, url=""){
      return Swal.fire({
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        icon: 'warning',
        //title: 'Muito bem',
        html: texto,
        //showConfirmButton: false,
        //timer: 30000,
        backdrop: true,
        focusConfirm: true
      }).then(function() {
        if(url && url.length>0){  
          //this.router.navigate([url]);
        }
      });
    }

    showErro(texto){
      return Swal.fire({
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        icon: 'error',
        title: 'Oops...',
        html: texto,
        //showConfirmButton: false,
        //timer: 3000,
        backdrop: true,
        focusConfirm: true
      })
    }

}



