import { Component, forwardRef, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GenericInputFieldComponent } from '../generic-input-field/generic-input-field.component';

@Component({
  selector: 'app-input-field-percent',
  templateUrl: './input-field-percent.component.html',
  styleUrls: ['./input-field-percent.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputFieldPercentComponent),
    multi: true
  }]
})
export class InputFieldPercentComponent extends GenericInputFieldComponent {

  constructor(injector: Injector) {
    super(injector);
  }

}
