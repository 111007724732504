<app-loader></app-loader>

<div class="wrapper">
  <!-- Sidebar  -->
  <app-sidenavbar id="sidebar" class="p-0" *ngIf="logado | async"></app-sidenavbar>

  <!-- Page Content  -->
  <div id="content" class="w-100 p-0">
    <div class="row px-3">
      <nav class="navbar navbar-expand-lg navbar-light bg-light mb-0 py-1" id="nav-b"
        [style.display]="(logado|async) ? 'block' : 'none'">
        <div class="container-fluid px-1 w-100">
          <button type="button" id="sidebarCollapse" class="btn btn-menu">
            <i class="fa fa-bars fa-lg" aria-hidden="true"></i>
          </button>
          <div class="navbar-collapse collapse float-right" id="navbarSupportedContent">

            <form *ngIf="allowChangeEmpresa()" [formGroup]="resourceForm"
              class="container-fluid px-0 box-current-empresa col-4">
              <div class="row">
                <div class="col-2 pt-1 text-right">
                  <label>Empresa</label>
                </div>
                <div class="col-10">
                  <app-select-field-search (change)="onChangeEmpresa($event)" [itens]="listEmpresa|async" label=""
                    formControlName="currentEmpresa">
                  </app-select-field-search>
                </div>
              </div>
            </form>
            <ul class="nav navbar-nav ml-auto">
              <!-- 
                      <li class="nav-item">
                          <a class="nav-link" href="#">Page</a>
                      </li>
                      -->
              <li class="nav-item nav-link info" *ngIf="!allowChangeEmpresa()">Empresa: <b>{{sessao.razaoSocial}}</b>
              </li>
              <li class="nav-item nav-link info">Login: <b>{{sessao.login}}</b></li>
              <li class="nav-item nav-link info">Grupo: <b>{{sessao.grupo}}</b></li>
              <li class="nav-item">
                <a class="nav-link" (click)="logoff()" href="javascript:void(0)"><i class="fa fa-sign-out"
                    aria-hidden="true"></i> Sair</a>
              </li>
            </ul>
          </div>

        </div>
      </nav>
      <div class="col-12">
        <router-outlet></router-outlet>
      </div>
    </div>

  </div>
</div>

<!-- 
<div class="container-fluid">
  <div class="row">
    
    <div class="col-2 px-0" *ngIf="logado | async">
      <a href="/" class="logo" [style.display]="!menu_visible ? 'inline-block' : 'none'">
        <img src="/assets/Siga_Parts.png"  width="110" alt="">
      </a>
      <button (click)="toggleMenu()" class="sidebar-toggle btn-menu" data-toggle="offcanvas" role="button">
        <i class="fa fa-bars fa-lg" aria-hidden="true"></i>
      </button>
    <app-sidenavbar [style.display]="menu_visible ? 'block' : 'none'" class="p-0"></app-sidenavbar>
    </div>
    <div class="{{(logado | async) && menu_visible?'col-10':'col-12'}}">
      <router-outlet></router-outlet>
    </div>
  </div>
-->