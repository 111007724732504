import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  constructor(
    private router: Router,
    private sessionService: SessionService,
  ) { }

  ngOnInit(): void {
  }

  displayMenu(): boolean{
    //return this.sessionService.hasCurrentSession();
    return true
  }

  // getUserName(): string{
  //   if (this.sessionService.hasCurrentSession){
  //     return this.sessionService.getCurrentSession().userName;
  //   }
  //   return '';
  // }

  logout(): void {
    this.sessionService.clear(); //TODO
    this.router.navigate(['/login']);
  }

  alterarEmpresaCorrente(): void {
    this.router.navigate(['/empresa']);
  }

}
