<label [attr.for]="formControlName" class="form-label {{isRequired()?'required':''}}">{{ label }}</label>

<ng-select
    [id]="formControlName"
    [formControl]="control"
    (change)="onChange($event)"
    [items]="itens" 
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    notFoundText="Não localizado" 
    placeholder="Selecione ..."
    [readonly]="isReadOnly"
    [tabindex]="isReadOnly?'-1':tabindex"
    >
</ng-select>

<form-field-error [form-control]="control"></form-field-error> 