<label [attr.for]="formControlName" class="form-label {{isRequired()?'required':''}}">{{ label }}</label>
<input
type="range"
class="form-range progress-bars w-100"
[id]="formControlName"
[formControl]="control"
[readonly]="isReadOnly"
[min]="min"
[max]="max"
[step]="step"
>
<form-field-error [form-control]="control"></form-field-error>